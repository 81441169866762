import { useEffect, useRef, useState } from 'react';
import { Button, CheckBoxInput, InputField, Paper, Select } from 'dibk-design';
import { ReactSortable } from 'react-sortablejs';
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers';
import { useGetDynamicMetadataQuery } from 'store/slices/checklistApiSlice';
import { useAddMetadataTypesMutation, useUpdateMetadataTypesMutation, useDeleteMetadataTypesMutation } from 'store/slices/adminApiSlice';
import { createRandomId, objectHasChanged } from 'helpers/dataHelpers';
import { toast } from 'helpers/formHelpers';
import './DynamicMetadataList.scss';

const VIEW_TYPE_OPTIONS = [
   { key: 'Tekstfelt', value: 'Textbox' },
   { key: 'Avkrysningsboks', value: 'Checkbox' },
   { key: 'Nedtrekksliste', value: 'Dropdownlist' }
];

function DynamicMetadataList() {
   const [dynamicMetadataTypes, setDynamicMetadataTypes] = useState([]);
   const originalDynamicMetadataTypes = useRef([]);
   const { data = [] } = useGetDynamicMetadataQuery(null, { refetchOnMountOrArgChange: true });
   const [addMetadataTypes] = useAddMetadataTypesMutation();
   const [updateMetadataTypes] = useUpdateMetadataTypesMutation();
   const [deleteMetadataTypes] = useDeleteMetadataTypesMutation();
   const theme = useRef(getEnvironmentVariable('theme'));

   useEffect(
      () => {
         if (data.length) {
            const mappedData = data
               .map(metadata => {
                  const { metadataValues, ...withoutMetadataValues } = metadata;
                  withoutMetadataValues.elementKey = createRandomId();

                  return withoutMetadataValues;
               });

            originalDynamicMetadataTypes.current = [...mappedData];
            setDynamicMetadataTypes([...mappedData]);
         }
      },
      [data]
   );

   function handleChange(event, index) {
      const value = event.target.type !== 'checkbox' ? event.target.value : event.target.checked;

      const updated = dynamicMetadataTypes.map((filter, idx) => {
         return idx !== index ? filter : { ...filter, [event.target.name]: value };
      });

      setDynamicMetadataTypes(updated);
   }

   function handleListSorted(sortedList) {
      sortedList.forEach((metadataType, index) => metadataType.orderNumber = index + 1);
      setDynamicMetadataTypes(sortedList);
   }

   function addEntry() {
      setDynamicMetadataTypes([...dynamicMetadataTypes, {
         id: 0,
         name: '',
         orderNumber: dynamicMetadataTypes.length,
         canUpdateValues: false,
         maxCountOfValues: '0',
         commonValue: false,
         facet: false,
         chosen: true,
         elementKey: createRandomId(),
         viewType: "Textbox"
      }]);
   }

   function deleteEntry(index) {
      setDynamicMetadataTypes(dynamicMetadataTypes.filter((_, idx) => idx !== index));
   }

   function autoSetCanUpdateValues(dynamicMetadataTypes) {
      return dynamicMetadataTypes.map(metadataType => { 
         return {
            ...metadataType,
            canUpdateValues: metadataType.viewType === 'Textbox' 
         }
      });
   }

   async function save() {
      handleListSorted(dynamicMetadataTypes);
      const dynamicMetadataTypesWithAutoSetValues = autoSetCanUpdateValues(dynamicMetadataTypes);

      if (!objectHasChanged(originalDynamicMetadataTypes.current, dynamicMetadataTypesWithAutoSetValues)) {
         return;
      }

      const toAdd = dynamicMetadataTypesWithAutoSetValues.filter(metadataType => metadataType.id === 0);
      const toUpdate = dynamicMetadataTypesWithAutoSetValues.filter(metadataType => metadataType.id !== 0);
      const toDelete = originalDynamicMetadataTypes.current.filter(metadataType => !dynamicMetadataTypesWithAutoSetValues.some(mdataType => mdataType.id === metadataType.id));

      try {
         await apiAdd(toAdd);
         await apiUpdate(toUpdate);
         await apiDelete(toDelete);

         toast.success('Endringene ble lagret');
         originalDynamicMetadataTypes.current = [...dynamicMetadataTypesWithAutoSetValues];
      } catch {
         toast.error('Kunne ikke lagre endringene');
      }
   }

   async function apiAdd(metadataTypes) {
      if (!metadataTypes.length) {
         return;
      }

      try {
         await addMetadataTypes(metadataTypes).unwrap();
      } catch {
         throw new Error();
      }
   }

   async function apiUpdate(metadataTypes) {
      if (!metadataTypes.length) {
         return;
      }

      try {
         await updateMetadataTypes(metadataTypes).unwrap();
      } catch {
         throw new Error();
      }
   }

   async function apiDelete(metadataTypes) {
      if (!metadataTypes.length) {
         return;
      }

      try {
         await deleteMetadataTypes(metadataTypes).unwrap();
      } catch {
         throw new Error();
      }
   }

   return (
      <div className="admin-right-content-container">
         <h1>Dynamiske metadata</h1>
         <Paper>
            {
               dynamicMetadataTypes.length ?
                  <div>
                     <div className="dynamic-metadata-table">
                        <div className="dynamic-metadata-header">
                           <div></div>
                           <div>Navn</div>
                           <div>Kan oppdatere verdier?</div>
                           <div>Antall verdier</div>
                           <div>Vises som</div>
                           <div>Er fellesverdi?</div>
                           <div>Vis som filter?</div>
                           <div></div>
                        </div>
                        <ReactSortable list={dynamicMetadataTypes} setList={handleListSorted} handle=".handle">
                           {
                              dynamicMetadataTypes.map((metadataType, index) => {
                                 return (
                                    <div key={'metadataType-' + metadataType.elementKey} className="dynamic-metadata">
                                       <div>
                                          <div className="handle"></div>
                                       </div>
                                       <div>
                                          <InputField
                                             elementKey={'name-' + metadataType.elementKey}
                                             id={'name-' + index}
                                             name="name"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={metadataType.name || ''}
                                          />
                                       </div>
                                       <div>
                                          {metadataType.viewType === "Checkbox" || metadataType.viewType === "Dropdownlist" ? (
                                             <CheckBoxInput
                                             id={'canUpdateValues-' + index}
                                             name="canUpdateValues"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={metadataType.canUpdateValues}
                                             disabled={true} // Temporarily disabled for now
                                             checked={false} // Temporarily unchecked for now
                                          />
                                          ) : null
                                          }
                                          
                                       </div>
                                       <div>
                                          <InputField
                                             elementKey={'maxCountOfValues-' + metadataType.elementKey}
                                             id={'maxCountOfValues-' + index}
                                             name="maxCountOfValues"
                                             type="number"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={metadataType.maxCountOfValues || ''}
                                          />
                                       </div>
                                       <div>
                                          <Select
                                             id={'viewType-' + index}
                                             name="viewType"
                                             options={VIEW_TYPE_OPTIONS} 
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={metadataType.viewType || 'Textbox'}
                                             theme={theme} 
                                          />                                       
                                       </div>                                       
                                       <div>
                                          <CheckBoxInput
                                             id={'commonValue-' + index}
                                             name="commonValue"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={metadataType.commonValue}
                                             checked={metadataType.commonValue}
                                          />
                                       </div>
                                       <div>
                                          <CheckBoxInput
                                             id={'facet-' + index}
                                             name="facet"
                                             onChange={event => handleChange(event, index)}
                                             defaultValue={metadataType.facet}
                                             checked={metadataType.facet}
                                          />
                                       </div>
                                       <div>
                                          {
                                             index === dynamicMetadataTypes.length - 1 ?
                                                <Button content="Legg til" color="primary" size="small" onClick={addEntry} theme={theme.current} /> :
                                                null
                                          }
                                          <Button content="Slett" color="default" size="small" onClick={() => deleteEntry(index)} theme={theme.current} />
                                       </div>
                                    </div>
                                 )
                              })
                           }
                        </ReactSortable>
                     </div>
                     <div>
                        { objectHasChanged(originalDynamicMetadataTypes.current, dynamicMetadataTypes) ?
                           <Button content="Lagre" color="primary" size="small" onClick={save} theme={theme.current} /> 
                           : null
                        }
                     </div>
                  </div> :
                  
                  <div>
                     <div>
                        { objectHasChanged(originalDynamicMetadataTypes.current, dynamicMetadataTypes) ? 
                           <Button content="Lagre" color="primary" size="small" onClick={save} theme={theme.current} /> 
                           : null
                        }
                     </div>
                  <div>
                  <Button content="Legg til" color="primary" size="small" onClick={addEntry} theme={theme.current} />
                  </div>
                  </div>
            }
         </Paper>
      </div>
   );
}

export default DynamicMetadataList;