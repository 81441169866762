// Dependencies
import React from "react";
import { Link } from "react-router-dom";

// DIBK Design
import { Header, Button } from "dibk-design";

// Stylesheets
import wizardStyle from "components/routes/Wizard.module.scss";

const DibkHomeContent = ({ theme, headerContent, checklistApiUrl }) => {
    return (
        <div className={wizardStyle.introText}>
            <Header content={headerContent} />
            <div className={wizardStyle.ingress}>
                <p>
                    Skal du behandle en byggesøknad? Bruk sjekklistene når du skal sjekke hvilke bestemmelser som
                    gjelder for søknaden.
                </p>
            </div>
            <Header content="Slik bruker du sjekklistene i saksbehandlingen" size={2} />
            <p>
                Sjekklistene gir deg som byggesaksbehandler en komplett liste over hvilke punkter kommunen skal vurdere
                i byggesaker.
            </p>
            <p>Du får også en forklaring på hvordan vurderingen skal gjøres.</p>
            <p>
                <i>Sjekklistene er basert på gjeldende regelverk for byggesaksbehandling</i>
            </p>
            <div className={wizardStyle.buttonRow}>
                <Button type="button" color="primary" theme={theme}>
                    <Link to="/checklist">Velg hvilken søknad du skal vurdere</Link>
                </Button>
            </div>
            <div className={wizardStyle.ingress}>
                <p>Sjekklistene kan også brukes via vårt API.</p>
                <a href={checklistApiUrl}>Api for sjekklistene</a>
            </div>
        </div>
    );
};

export default DibkHomeContent;
