import React, { useEffect, useRef, useState } from 'react';
import { Button, CheckBoxInput, InputField, Paper } from 'dibk-design';
import { useGetStaticMetadataQuery } from 'store/slices/checklistApiSlice';
import { useUpdateStaticMetadatasMutation } from 'store/slices/adminApiSlice';
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';
import { toast } from 'helpers/formHelpers';
import './StaticMetadataList.scss';

function StaticMetadataList() {
   const [staticMetadatas, setStaticMetadatas] = useState([]);
   const { data = [] } = useGetStaticMetadataQuery();
   const [updateStaticMetadatas] = useUpdateStaticMetadatasMutation();
   const theme = useRef(getEnvironmentVariable('theme'));

   useEffect(
      () => {
         if (data.length) {
            setStaticMetadatas(data);
         }
      },
      [data]
   );

   function handleChange(event, index) {
      const value = event.target.type !== 'checkbox' ? event.target.value : event.target.checked;

      const updated = staticMetadatas.map((filter, idx) => {
         return idx !== index ? filter : { ...filter, [event.target.name]: value };
      });

      setStaticMetadatas(updated);
   }

   async function save() {
      try {
         await updateStaticMetadatas(staticMetadatas).unwrap();
         toast.success('Endringene ble lagret');
      } catch {
         toast.error('Kunne ikke lagre endringene');
      }
   }

   return (
      <div className="admin-right-content-container">
         <h1>Faste metadata</h1>
         <Paper>
            {
               staticMetadatas.length ?
                  <div>
                     <div className="static-metadata-filters">
                        <div className="static-metadata-filters-header">
                           <div>Navn</div>
                           <div>Beskrivelse</div>
                           <div>Vis som filter?</div>
                           <div>Vis på detaljside?</div>
                        </div>
                        {
                           staticMetadatas.map((filter, index) => {
                              return (
                                 <div key={'filter-' + index} className="static-metadata-filter">
                                    <div>
                                       <InputField
                                          elementKey={'name-' + index}
                                          id={'name-' + index}
                                          name="name"
                                          onChange={event => handleChange(event, index)}
                                          defaultValue={filter.name || ''}
                                       />
                                    </div>
                                    <div>
                                       <InputField
                                          elementKey={'description-' + index}
                                          id={'description-' + index}
                                          name="description"
                                          onChange={event => handleChange(event, index)}
                                          defaultValue={filter.description || ''}
                                       />
                                    </div>
                                    <div>
                                       <CheckBoxInput
                                          id={'showAsFacetFilter-' + index}
                                          name="showAsFacetFilter"
                                          onChange={event => handleChange(event, index)}
                                          defaultValue={filter.showAsFacetFilter}
                                          checked={filter.showAsFacetFilter}
                                       />
                                    </div>
                                    <div>
                                       <CheckBoxInput
                                          id={'showOnDetailPage-' + index}
                                          name="showOnDetailPage"
                                          onChange={event => handleChange(event, index)}
                                          defaultValue={filter.showOnDetailPage}
                                          checked={filter.showOnDetailPage}
                                       />
                                    </div>
                                 </div>
                              )
                           })
                        }
                     </div>

                     <Button content="Lagre" color="primary" size="small" onClick={save} theme={theme.current} />
                  </div> :
                  null
            }
         </Paper>
      </div>
   );
}

export default StaticMetadataList;