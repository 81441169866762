// Dependencies
import React from "react";

// Components
import DibkHomeContent from "./HomeContent/Dibk";
import PlanHomeContent from "./HomeContent/Plan";
import PlanbestemmelserHomeContent from "./HomeContent/Planbestemmelser";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

const HomeContent = () => {
    function getHomeContent(theme) {
        switch (theme.appName) {
            case "Dibk Sjekkliste":
                return (
                    <DibkHomeContent
                        theme={theme}
                        headerContent={getEnvironmentVariable("Header")}
                        checklistApiUrl={checklistApiUrl}
                    />
                );
            case "Fellestjenester plan":
                return (
                    <PlanHomeContent
                        theme={theme}
                        headerContent={getEnvironmentVariable("Header")}
                        checklistApiUrl={checklistApiUrl}
                    />
                );
            case "Nasjonal planbestemmelseskatalog":
            return (
                <PlanbestemmelserHomeContent
                    theme={theme}
                    headerContent={getEnvironmentVariable("Header")}
                    checklistApiUrl={checklistApiUrl}
                />
            );
            default:
                return (
                    <DibkHomeContent
                        theme={theme}
                        headerContent={getEnvironmentVariable("Header")}
                        checklistApiUrl={checklistApiUrl}
                    />
                );
        }
    }

    const theme = getEnvironmentVariable("theme");
    const checklistApiUrl = `${getEnvironmentVariable("backendHost")}/api/sjekkliste`;
    return getHomeContent(theme);
};

export default HomeContent;
