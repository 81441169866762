// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// DIBK Design
import { LoadingAnimation, Button } from "dibk-design";

// Components
import ActivitySection from "./Activities/ActivitySection";

// Actions
import { fetchActivities } from "../../actions/ActivitiesActions";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// Stylesheets
import style from "./Activities.module.scss";

import Footer from "components/partials/Footer";

class Activities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingActivities: false
        };
    }

    componentDidMount() {
        const processCategoryKey = this.props.processCategoryKey;
        const accessToken =
            this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token
                ? this.props.oidc.user.access_token
                : null;
        this.setState({ isLoadingActivities: true });
        this.props.fetchActivities(processCategoryKey, this.props.location.search, accessToken).then(() => {
            this.setState({ isLoadingActivities: false });
        });
    }

    componentDidUpdate(prevProps) {
        const processCategoryKey = this.props.processCategoryKey;
        const processCategoryKeyHasChanged = prevProps.processCategoryKey !== processCategoryKey;
        const accessToken =
            this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token
                ? this.props.oidc.user.access_token
                : null;
        const prevAccessToken =
            prevProps.oidc && prevProps.oidc.user && prevProps.oidc.user.access_token
                ? prevProps.oidc.user.access_token
                : null;
        const accessTokenHasChanged = prevAccessToken !== accessToken;

        const oldUrlParameterString = prevProps.location && prevProps.location.search ? prevProps.location.search : "";
        const newUrlParameterString =
            this.props.location && this.props.location.search ? this.props.location.search : "";
        const urlParameterStringHasChanged = oldUrlParameterString !== newUrlParameterString;

        if (processCategoryKeyHasChanged || urlParameterStringHasChanged || accessTokenHasChanged) {
            const accessToken =
                this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token
                    ? this.props.oidc.user.access_token
                    : null;
            if (!this.state.isLoadingActivities) {
                this.setState({ isLoadingActivities: true });
            }
            this.props.fetchActivities(processCategoryKey, this.props.location.search, accessToken).then(() => {
                if (this.state.isLoadingActivities) {
                    this.setState({ isLoadingActivities: false });
                }
            });
        }
    }

    getNumberOfActivities(activitiesByType) {
        let numberOfActivities = 0;
        if (activitiesByType && activitiesByType.length) {
            activitiesByType.forEach((type) => {
                numberOfActivities += type.activities && type.activities.length ? type.activities.length : 0;
            });
        }
        return numberOfActivities;
    }

    renderActivitySections(activitySections) {
        const hasActivitySections = activitySections && activitySections.length;
        return hasActivitySections
            ? activitySections.map((activitySection, key) => {
                  return <ActivitySection key={key} activitySection={activitySection} />;
              })
            : "";
    }

    renderLoadingMessageElement() {
        return (
            <div className={style.loadingMessageContainer}>
                <LoadingAnimation message="Henter sjekkpunkter..." />
            </div>
        );
    }

    render() {
        const theme = getEnvironmentVariable("theme");
        const isAuthenticated = this.props.oidc && this.props.oidc.user;
        return this.state.isLoadingActivities ? (
            this.renderLoadingMessageElement()
        ) : (
            <div>
                <div className={style.topbarflex}>
                    <div aria-live="polite">
                        Antall hovedsjekkpunkter:{" "}
                        <strong>{this.getNumberOfActivities(this.props.searchFilteredActivities)}</strong>
                    </div>
                    {isAuthenticated ? (
                        <Button color="primary" theme={theme}>
                            <Link title="Opprett nytt sjekkpunkt" className={style.btnAddMain} to={"/create-activity"}>
                                Opprett nytt sjekkpunkt
                            </Link>
                        </Button>
                    ) : null}
                </div>
                {this.renderActivitySections(this.props.searchFilteredActivities)}
                <Footer />
            </div>
        );
    }
}

Activities.propTypes = {
    processCategoryKey: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    location: state.router.location,
    oidc: state.oidc,
    activities: state.activities,
    searchFilteredActivities: state.searchFilteredActivities
});

const mapDispatchToProps = {
    fetchActivities
};

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
