// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// DIBK Design
import {Header} from 'dibk-design';

// Components
import ActivitySearch from 'components/partials/ActivitySearch';
import ProcessCategorySection from 'components/partials/Facets/ProcessCategorySection';
import FacetField from 'components/partials/Facets/FacetField';
import ValidDate from 'components/partials/Facets/ValidDate';

// Actions
import { fetchFacets } from 'actions/FacetsActions';
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

// Stylesheets
import style from './Facets.module.scss';

class Facets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    }
  }

  componentDidMount() {
    this.props.fetchFacets(true);
  }
  renderUserFeedbackEmailAddress() {
    return getEnvironmentVariable('userFeedbackEmail');
  }
  renderFilterSections(facets) {
    const hasFacets = facets && Object.keys(facets).length;
    return hasFacets
      ? Object.keys(facets).map(filterSectionKey => {
        const facetField = facets[filterSectionKey];
        return <FacetField facetField={facetField} key={filterSectionKey} />;
      })
      : '';
  }

  renderUserFeedback(){
    return <div className={style.infoBox}>
        <button type="button" className={style.feedbackButton} onClick={() => this.toggleInfo()} aria-expanded={this.state.isExpanded} aria-describedby="feedbackDescription" >
          Gi tilbakemelding <FontAwesomeIcon icon={"coffee"} />
        </button>
        <span aria-live='polite' className={`${this.state.isExpanded ? style.textcontentOpen : style.textcontent}`}>
          { 
          this.state.isExpanded ? (
            <React.Fragment>
              Har du tilbakemeldinger eller forslag til forbedringer, eller har du funnet feil eller mangler? <a href={`mailto:${this.renderUserFeedbackEmailAddress()}?subject='fra listen'`}>Send oss en henvendelse på e-post.</a>
            </React.Fragment>
          )
        : null
        }
        </span>
      </div>
  }


  toggleInfo() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }
  render() {
    return (<nav className={style.filter}>
      { getEnvironmentVariable('showUserFeedback') ? this.renderUserFeedback(): ''}
      <fieldset>
      { getEnvironmentVariable('showFilterHelpText') ? <legend><Header content="Bruk valgene nedenfor for å justere innholdet i listen" size={5} htmlTag="h2" /></legend> : ''}
        <ActivitySearch searchableProperties={
          ['referenceId', 'name', 'nameNynorsk', 'description', 'descriptionNynorsk']
        } />        
        <ProcessCategorySection /> 
        {getEnvironmentVariable('hideValidDate' ) === true ? '' : 
        <div>
        <ValidDate />
        <div className={style.descriptiondate}>Sjekkpunkt kan ha en gyldig til/fra dato. Velg en dato for å se hvilke sjekkpunkt som var gyldige på den aktuelle datoen "</div>
        </div>}
        
        
        {
          this.props.facets
            ? this.renderFilterSections(this.props.facets)
            : ''
        }</fieldset>
    </nav>)
  }
}

const mapStateToProps = state => ({ facets: state.facets });

const mapDispatchToProps = {
  fetchFacets
};

export default connect(mapStateToProps, mapDispatchToProps)(Facets);
