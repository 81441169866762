import { useEffect, useRef, useState } from 'react';
import { Paper } from 'dibk-design';
import { useGetDynamicMetadataQuery } from 'store/slices/checklistApiSlice';
import DynamicMetadataValues from '../DynamicMetadataValues/DynamicMetadataValues';
import './DynamicMetadataValuesList.scss';

function DynamicMetadataValuesList() {
   const [dynamicMetadataTypes, setDynamicMetadataTypes] = useState([]);
   const originalDynamicMetadataTypes = useRef([]);
   const { data = [] } = useGetDynamicMetadataQuery(null, { refetchOnMountOrArgChange: true });

   useEffect(
      () => {
         if (data.length) {
            const mappedData = data
               .filter(metadata => !metadata.canUpdateValues);

            originalDynamicMetadataTypes.current = [...mappedData];
            setDynamicMetadataTypes([...mappedData]);
         }
      },
      [data]
   );

   return (
      <div className="admin-right-content-container">
         <h1>Dynamiske metadataverdier</h1>
         <Paper>
            {
               dynamicMetadataTypes.length ?
                  dynamicMetadataTypes.map((metadataType, index) => <DynamicMetadataValues key={'metadataType-' + index} metadataType={metadataType} />) :
                  null
            }
         </Paper>
      </div>
   )
}

export default DynamicMetadataValuesList;