// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";

// DIBK Design
import { Button, NavigationBar } from "dibk-design";

// Helpers
import {
    getEnvironmentVariable,
    getNavigationBarBackgroundColor,
    getNavigationBarTextColor
} from "helpers/environmentVariableHelpers.js";
import { renderUserFeedbackHtml } from "helpers/userFeedbackHelpers";

// Actions
import { updateSelectedLanguage } from "actions/SelectedLanguageActions";

// Stylesheets
import style from "./MainNavigationBar.module.scss";

class Home extends Component {
    renderLanguageButtons(availableLanguages, selectedLanguage) {
        const contentStyle = {
            color: getNavigationBarTextColor(),
            backgroundColor: getNavigationBarBackgroundColor(),
            borderColor: getNavigationBarTextColor()
        };
        return availableLanguages && availableLanguages.length && selectedLanguage
            ? availableLanguages
                  .filter((availableLanguage) => {
                      return availableLanguage.key !== selectedLanguage;
                  })
                  .map((availableLanguage) => {
                      return (
                          <div
                              key={availableLanguage.key}
                              title={"Velg om du vil se sjekkpunktene på " + availableLanguage.value}
                              className={style.languageBarButtonContainer}
                          >
                              <Button
                                  size="small"
                                  type="button"
                                  color="primary"
                                  noHover
                                  onClick={() => {
                                      this.props.updateSelectedLanguage(availableLanguage.key);
                                  }}
                                  style={contentStyle}
                              >
                                  {"Bytt til " + availableLanguage.value}
                              </Button>
                          </div>
                      );
                  })
            : null;
    }

    render() {
        const homelink = getEnvironmentVariable("homelink");
        const theme = getEnvironmentVariable("theme");
        const supportsNynorsk = getEnvironmentVariable("supportsNynorsk");
        const navigationBarContentStyle = {
            color: getNavigationBarTextColor()
        };
        const userFeedbackLabel = getEnvironmentVariable("userFeedbackLabel", {
            mailToAddress: getEnvironmentVariable("userFeedbackEmail")
        });
        const userFeedbackHtml = renderUserFeedbackHtml(userFeedbackLabel, true);
        return (
            <NavigationBar
                logoLink={`${homelink}`}
                logoLinkTitle="Gå til første side av veiviseren"
                theme={theme}
                mainContentId="main-content"
            >
                <div className={style.navigationBarContent} style={navigationBarContentStyle}>
                    <div className={style.navigationBarText}>
                        {userFeedbackHtml?.length ? <span>{userFeedbackHtml}</span> : null}
                    </div>
                    {supportsNynorsk
                        ? this.renderLanguageButtons(this.props.availableLanguages, this.props.selectedLanguage)
                        : null}
                </div>
            </NavigationBar>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.oidc.user,
    availableLanguages: state.availableLanguages,
    selectedLanguage: state.selectedLanguage
});

const mapDispatchToProps = {
    updateSelectedLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
