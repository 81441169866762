import React from 'react';
import { Header } from 'dibk-design';
import { Switch, Route, NavLink, Redirect, useRouteMatch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { StaticMetadataList, DynamicMetadataList, DynamicMetadataValuesList, StaticMetadataValuesList, ValidationRulesList } from 'components/partials/Admin';
import useAuthentication from 'hooks/useAuthentication';
import 'react-toastify/dist/ReactToastify.css';
import './Admin.scss';

function Admin({ userManager }) {
   const { path, url } = useRouteMatch();
   const isAuthenticated = useAuthentication(userManager);

   return (
      isAuthenticated ?
         <div className="admin">
            <div className="admin-left-content">
               <Header content="Administrasjon" size={4} />

               <ul className="admin-menu">
                  <li>
                     <NavLink to={`${url}/static-metadata`}>Faste metadata</NavLink>
                  </li>
                  <li>
                     <NavLink to={`${url}/static-metadata-values`}>Faste metadataverdier</NavLink>
                  </li>
                  <li>
                     <NavLink to={`${url}/dynamic-metadata`}>Dynamiske metadata</NavLink>
                  </li>
                  <li>
                     <NavLink to={`${url}/dynamic-metadata-values`}>Dynamiske metadataverdier</NavLink>
                  </li>
                  <li>
                     <NavLink to={`${url}/validation-rules`}>Valideringsregler</NavLink>
                  </li>
               </ul>
            </div>

            <div className="admin-right-content">
               <Switch>
                  <Route exact path={path}>
                     <Redirect to={`${url}/static-metadata`} />
                  </Route>
                  <Route path={`${path}/static-metadata`}>
                     <StaticMetadataList />
                  </Route>
                  <Route path={`${path}/static-metadata-values`}>
                     <StaticMetadataValuesList />
                  </Route>
                  <Route path={`${path}/dynamic-metadata`}>
                     <DynamicMetadataList />
                  </Route>
                  <Route path={`${path}/dynamic-metadata-values`}>
                     <DynamicMetadataValuesList />
                  </Route>
                  <Route path={`${path}/validation-rules`}>
                     <ValidationRulesList />
                  </Route>
               </Switch>
            </div>

            <ToastContainer />
         </div> :
         null
   );
}

export default Admin;