// Dependencies
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// DIBK Design
import { Accordion, Button, Dialog, Header } from "dibk-design";

// Components
import ValidationRuleIds from "./ActionListItem/ValidationRuleIds";

// Actions
import { deleteValidationRules, postValidationRules } from "actions/ValidationRulesActions";

// Helpers
import { toast } from "helpers/formHelpers";
import { addTempIdsForValidationRuleIds, removeTempIdsForValidationRuleIds } from "helpers/validationRuleHelpers";

// Stylesheets
import style from "./ActionListItem.module.scss";

function ActionListItem({ action, activityId, onValidationRulesSave }) {
    const dispatch = useDispatch();

    // State
    const [showDetails, setShowDetails] = useState(false);
    const [validationRulesVersions, setValidationRulesVersions] = useState(
        addTempIdsForValidationRuleIds(action.supportingDataValidationRuleId)
    );

    // Redux store
    const oidc = useSelector((state) => state.oidc);

    function handleAddValidationRulesVersion() {
        const newValidationRulesVersions = [...validationRulesVersions];
        newValidationRulesVersions.push({ tempId: uuidv4(), validationRuleIds: [] });
        setValidationRulesVersions(newValidationRulesVersions);
    }

    function isMatchingValidationRulesVersion(validationRulesVersion, validationRulesVersionToMatch) {
        const metadataIdHasMatch =
            validationRulesVersion.id &&
            validationRulesVersionToMatch.id &&
            validationRulesVersion.id === validationRulesVersionToMatch.id;
        const tempMetadataIdHasMatch =
            validationRulesVersion.tempMetadataId &&
            validationRulesVersionToMatch.tempMetadataId &&
            validationRulesVersion.tempMetadataId === validationRulesVersionToMatch.tempMetadataId;
        return metadataIdHasMatch || tempMetadataIdHasMatch;
    }

    function findIndexForValidationRulesVersion(validationRulesVersions, validationRulesVersionToMatch) {
        return validationRulesVersions.findIndex((validationRulesVersion) => {
            return isMatchingValidationRulesVersion(validationRulesVersion, validationRulesVersionToMatch);
        });
    }

    function handleRemoveValidationRulesVersion(validationRuleVersionToRemove) {
        const accessToken = !!oidc?.user?.access_token?.length && oidc.user.access_token;
        dispatch(deleteValidationRules(validationRuleVersionToRemove.id, accessToken)).then((res) => {
            if (res.ok) {
                const newValidationRulesVersions = [...validationRulesVersions];
                const validationRulesVersionIndex = findIndexForValidationRulesVersion(
                    newValidationRulesVersions,
                    validationRuleVersionToRemove
                );
                if (validationRulesVersionIndex > -1) {
                    newValidationRulesVersions.splice(validationRulesVersionIndex, 1);
                }
                setValidationRulesVersions(newValidationRulesVersions);
                toast.success("Versjon ble slettet");
            } else {
                toast.error("Kunne ikke slette versjon");
            }
        });

        const newValidationRulesVersions = validationRulesVersions.filter((validationRulesVersion) => {
            if (!!validationRuleVersionToRemove?.id?.length) {
                return validationRulesVersion.id !== validationRuleVersionToRemove.id;
            } else if (!!validationRuleVersionToRemove?.tempId?.length) {
                return validationRulesVersion.tempId !== validationRuleVersionToRemove.tempId;
            } else {
                return validationRulesVersions;
            }
        });
        setValidationRulesVersions(newValidationRulesVersions);
    }

    function handleOnChangeValidationRule(validationRuleId, versionIndex, validationRuleIndex) {
        const newValidationRulesVersions = [...validationRulesVersions];
        newValidationRulesVersions[versionIndex].validationRuleIds[validationRuleIndex] = validationRuleId;
        setValidationRulesVersions(newValidationRulesVersions);
    }

    function handleRemoveValidationRule(versionIndex, validationRuleIndex) {
        const newValidationRulesVersions = [...validationRulesVersions];
        const newValidationRuleIds = [...validationRulesVersions[versionIndex].validationRuleIds];
        newValidationRuleIds.splice(validationRuleIndex, 1);
        newValidationRulesVersions[versionIndex].validationRuleIds = newValidationRuleIds;
        setValidationRulesVersions(newValidationRulesVersions);
    }

    function handleAddValidationRule(versionIndex) {
        const newValidationRulesVersions = [...validationRulesVersions];
        newValidationRulesVersions[versionIndex].validationRuleIds.push({ tempId: uuidv4(), value: "" });
        setValidationRulesVersions(newValidationRulesVersions);
    }

    function handleSave() {
        const validationRules = removeTempIdsForValidationRuleIds(validationRulesVersions);
        const requestBody = [
            {
                activityId,
                actionId: action.actionId,
                validationRules
            }
        ];
        const accessToken = !!oidc?.user?.access_token?.length && oidc.user.access_token;
        dispatch(postValidationRules(requestBody, accessToken)).then((res) => {
            if (res.ok) {
                onValidationRulesSave(accessToken);
                toast.success("Endringene ble lagret");
            } else {
                toast.error("Kunne ikke lagre endringene");
            }
        });
    }

    function handleOnClickOutsideDialog() {
        setShowDetails(false);
    }

    function getValidationRuleVersionInputFieldKey(version) {
        return Number.isInteger(version?.id) ? version.id : version.tempId;
    }

    function renderValidationRuleIds(validationRuleIds, versionIndex) {
        return !!validationRuleIds?.length ? (
            validationRuleIds.map((validationRuleId, validationRuleIndex) => {
                const inputKey = `validationRuleId-${validationRuleId.tempId}`;
                return (
                    <ValidationRuleIds
                        key={inputKey}
                        validationRuleId={validationRuleId}
                        validationRuleIndex={validationRuleIndex}
                        versionIndex={versionIndex}
                        handleOnChangeValidationRule={handleOnChangeValidationRule}
                        handleRemoveValidationRule={handleRemoveValidationRule}
                    />
                );
            })
        ) : (
            <div>Ingen regler lagt til</div>
        );
    }

    function renderVersions(versions) {
        return !!versions?.length ? (
            versions.map((version, versionIndex) => {
                const versionKey = `${activityId}-${action.actionId}-${getValidationRuleVersionInputFieldKey(
                    version
                )}-input`;
                const numberOfValidationRuleIds = version?.validationRuleIds?.length
                    ? version?.validationRuleIds?.length
                    : 0;
                const accordionTitle = version?.id
                    ? `Versjon ${version.id.toString()} (${numberOfValidationRuleIds} regler)`
                    : `Ny versjon (${numberOfValidationRuleIds} regler)`;
                return (
                    <div key={`${versionKey}-listItem`} className={style.accordionMargin}>
                        <Accordion title={accordionTitle}>
                            {renderValidationRuleIds(version.validationRuleIds, versionIndex)}
                            <Button size="small" onClick={() => handleAddValidationRule(versionIndex)}>
                                Legg til valideringsregel
                            </Button>
                            <Button size="small" onClick={() => handleRemoveValidationRulesVersion(version)}>
                                Slett versjon
                            </Button>
                        </Accordion>
                    </div>
                );
            })
        ) : (
            <div>Ingen versjoner lagt til</div>
        );
    }

    useEffect(() => {
        setValidationRulesVersions(addTempIdsForValidationRuleIds(action.supportingDataValidationRuleId));
    }, [action.supportingDataValidationRuleId]);

    return (
        <Fragment>
            <button
                onClick={() => {
                    setShowDetails(true);
                }}
                className={style.actionListItemButton}
            >
                {action.actionValue ? "Ja" : "Nei"} - {action.actionType}
                <span className={style.badge}>{validationRulesVersions.length} versjoner</span>
            </button>
            {showDetails && (
                <Dialog onClickOutside={handleOnClickOutsideDialog} closeButton maxWidth="680px">
                    <Header size={2}>
                        {action.actionValue ? "Ja" : "Nei"} - {action.actionType}
                    </Header>
                    {renderVersions(validationRulesVersions)}
                    <Button onClick={() => handleAddValidationRulesVersion()}>Legg til versjon</Button>
                    <Button onClick={() => handleSave()} color="primary">
                        Lagre
                    </Button>
                </Dialog>
            )}
        </Fragment>
    );
}

export default ActionListItem;
