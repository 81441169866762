// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


// components
import ActivityListItem from './ActivitySection/ActivityListItem'

import { Paper, Header } from 'dibk-design';


class ActivitySection extends Component {

  renderActivitySections(activitySection) {
    const hasActivities = activitySection && activitySection.activities.length;

    return hasActivities
      ? activitySection.activities.map(activity => {
        return (<ActivityListItem key={activity.id} activity={activity} />);
      })
      : '';
  }

  render() {

    return (<Paper>
      <Header content={this.props.activitySection.category} size={2} />

      {this.renderActivitySections(this.props.activitySection)}
    </Paper>)
  }
}

ActivitySection.propTypes = {
  activitySection: PropTypes.object.isRequired
};

export default connect(null, null)(ActivitySection);
