// Dependencies
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MDEditor from "@uiw/react-md-editor";
import { v4 as uuidv4 } from "uuid";

// Components
import EnterpriseTerms from "components/partials/EnterpriseTerms";

// DIBK Design
import {
    Button,
    CheckBoxList,
    CheckBoxListItem,
    DragAndDropFileInput,
    Header,
    InputField,
    Label,
    List,
    ListItem,
    Paper,
    RadioButtonList,
    RadioButtonListItem,
    Select
} from "dibk-design";
import Modal from "components/template/Modal";

// Actions
import {
    fetchActivity,
    createDraftActivity,
    updateDraftActivity,
    updatePublishedActivity
} from "actions/ActivityActions";
import { fetchActivities } from "actions/ActivitiesActions";
import { updateSelectedEnterpriseTerms } from "actions/SelectedEnterpriseTermsActions";
import { fetchFacets } from "actions/FacetsActions";
import { fetchProcessCategories } from "actions/ProcessCategoriesActions";
import { fetchActions } from "actions/ActionsActions";
import { fetchMetadataTypes } from "actions/MetadataTypesActions";

// Helpers
import { getEnvironmentVariable, getThemePaletteBackgroundColor } from "helpers/environmentVariableHelpers.js";
import { getActivityName, getActivityDescription } from "helpers/languageHelpers";
import { getOrderedFacetsByNameArray } from "helpers/facetFilterHelpers";
import {
    convertFromTimestampToInputDate,
    convertFromInputDateToTimestamp,
    convertFromTimestampToText
} from "helpers/dateHelpers";
import { getFocusableElementsInsideElement, setFocusToElement } from "helpers/guiHelpers";

// Assets
import loadingAnimation from "assets/images/spinner.svg";

// Stylesheets
import style from "./ActivityDetails.module.scss";
import { renderUserFeedbackHtml } from "helpers/userFeedbackHelpers";

class ActivityDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editable: false,
            redirect: null,
            activity: null,
            enterPriseTermsIsExpanded: false,
            actionsIsExpanded: false,
            actionsWithEmptyValues: null,
            lawReferencesIsExpanded: false,
            isLoading: false,
            loadingMessage: null,
            showDeleteParentReferenceIdModal: false,
            hasSelectedIamgeFile: false,
            selectedImageFileName: null,
            imageFileErrorMessage: null
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.keyDownFunction = this.keyDownFunction.bind(this);
        this.handleImageFileInputChange = this.handleImageFileInputChange.bind(this);
        this.getMetadataForMetadataType = this.getMetadataForMetadataType.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        document.addEventListener("keydown", this.keyDownFunction, false);
        const activityId = this.props.activityId;
        this.props.fetchActivity(activityId).then((action) => {
            const activity = action && action.payload ? action.payload : null;
            const selectedEnterpriseTerms = activity?.enterpriseTerms ? activity.enterpriseTerms : [];
            this.props.updateSelectedEnterpriseTerms(selectedEnterpriseTerms);
            this.setState({ activity: activity });
        });
        const hasFilters = this.props.filters && this.props.filters.length;
        if (!hasFilters) {
            this.props.fetchFacets();
        }
        const hasProcessCategories = this.props.processCategories && Object.keys(this.props.processCategories).length;
        if (!hasProcessCategories) {
            this.props.fetchProcessCategories();
        }
        const hasActions = this.props.actions && this.props.actions.length;
        if (!hasActions) {
            this.props.fetchActions();
        }
        const hasMetadataTypes = this.props.metadataTypes && this.props.metadataTypes.length;
        if (!hasMetadataTypes) {
            this.props.fetchMetadataTypes();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener("keydown", this.keyDownFunction, false);
    }

    componentDidUpdate(prevProps) {
        const activityId = this.props.activityId;
        const activityIdHasChanged = prevProps.activityId !== activityId;
        if (activityIdHasChanged) {
            if (this.state.redirect) {
                this.setState({ redirect: null });
            }
            this.props.fetchActivity(activityId).then((action) => {
                this.setState({ activity: action?.payload });
            });
        }

        if (this.wrapperRef && this.state.activity && !this.state.focusTrapIsCreated) {
            setFocusToElement(this.wrapperRef);
            const focusableElements = getFocusableElementsInsideElement(this.wrapperRef);
            const firstFocusableElement = focusableElements[0];
            const lastFocusableElement = focusableElements[focusableElements.length - 1];
            firstFocusableElement.onkeydown = (event) => {
                if (event.keyCode === 9 && event.shiftKey) {
                    lastFocusableElement.focus();
                }
            };
            lastFocusableElement.onclick = () => {
                firstFocusableElement.focus();
            };
            lastFocusableElement.onkeydown = (event) => {
                if (event.keyCode === 9 && !event.shiftKey) {
                    event.preventDefault();
                    firstFocusableElement.focus();
                }
            };
            this.setState({
                focusTrapIsCreated: true
            });
        }
    }

    keyDownFunction(event) {
        switch (event.keyCode) {
            case 27: // Escape
                if (!event.defaultPrevented) {
                    this.handleOnCloseRedirect();
                }
                break;
            default:
                return null;
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOnCloseRedirect() {
        const processCategoryKey = this.props.processCategoryKey;
        const activityId = this.props.activityId;
        const redirect = this.props.sidebarView
            ? `/checklist/${processCategoryKey}${this.props.location.search}`
            : `/checklist/${processCategoryKey}/${activityId}${this.props.location.search}`;
        this.setState({
            redirect
        });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.handleOnCloseRedirect();
        }
    }

    hasParentReferenceId() {
        return this.state.activity.parentReferenceId && this.state.activity.parentReferenceId !== null;
    }

    removeParentReferenceId() {
        this.setState({
            activity: {
                ...this.state.activity,
                parentReferenceId: null
            }
        });
    }

    handleRemoveParentReferenceIdModal() {
        this.removeParentReferenceId();

        this.setState({ showDeleteParentReferenceIdModal: false });
    }

    renderDeleteParentResponseModal(theme) {
        const hideDeleteParentResponseModal = () => {
            this.setState({
                showDeleteParentReferenceIdModal: false
            });
        };
        return (
            <Modal onClickOutside={hideDeleteParentResponseModal}>
                <div className={style.modalContentContainer}>
                    <Header content="Er du sikker?" />
                    <Paper>
                        Dette vil ikke være mulig å endre tilbake sjekkpunktet som underpunkt. Ved å klikke 'Endre til
                        hovedpunkt' vil dette sjekkpunkt bli et hovedpunkt.{" "}
                    </Paper>
                    <Button
                        type="button"
                        content="Avbryt"
                        onClick={() => this.setState({ showDeleteParentReferenceIdModal: false })}
                        theme={theme}
                    />
                    <Button
                        type="button"
                        onClick={() => this.handleRemoveParentReferenceIdModal()}
                        content="Endre til hovedpunkt"
                        color="primary"
                        theme={theme}
                    />
                </div>
            </Modal>
        );
    }

    getProcessCategoryByKey(processCategories, processCategoryKey) {
        const processCategory = processCategories.filters[processCategoryKey];
        return {
            key: processCategory.name ? processCategory.name : processCategoryKey,
            value: processCategoryKey
        };
    }

    getProcessCategoryOptions() {
        const hasProcessCategories =
            this.props.processCategories &&
            this.props.processCategories.filters &&
            Object.keys(this.props.processCategories.filters).length;
        if (hasProcessCategories) {
            const processCategories = Object.keys(this.props.processCategories.filters).map((processCategoryKey) => {
                return this.getProcessCategoryByKey(processCategories, processCategoryKey);
            });
            return processCategories;
        } else {
            return [];
        }
    }

    convertFilterTypeToOptionValues(facetField) {
        const hasFilters = facetField?.filters && Object.keys(facetField.filters).length;

        return hasFilters
            ? getOrderedFacetsByNameArray(facetField.filters).map((facet) => {
                  return {
                      key: facet?.name || facet.facetKey,
                      value: facet.id,
                      isGroup: facet.isGroup,
                      filters: facet?.filters ? this.convertFilterTypeToOptionValues(facet) : null
                  };
              })
            : [];
    }

    convertMetadataValuesToOptionValues(metadataType) {
        const hasFilters = metadataType?.metadataValues && Object.keys(metadataType.metadataValues).length;
        return hasFilters
            ? getOrderedFacetsByNameArray(metadataType.metadataValues).map((metadata) => {
                  return {
                      key: metadata.value,
                      value: metadata.id,
                      isGroup: false,
                      filters: null
                  };
              })
            : [];
    }

    convertActionsTypeToOptionValues(actions) {
        return actions && actions.length
            ? actions.map((action) => {
                  return {
                      key: action.actionType,
                      value: action.actionTypeCode
                  };
              })
            : [];
    }

    getOptionValuesForFilterType(filters, filterModelName) {
        const hasFilters = filters && filters.length;
        if (hasFilters) {
            const filterType = filters.find((filter) => {
                return filter.modelName === filterModelName;
            });
            return this.convertFilterTypeToOptionValues(filterType);
        }
        return [];
    }

    getMetadataValuesForMetadataType(filters, filterModelName) {
        const hasFilters = filters && filters.length;
        if (hasFilters) {
            const filterType = filters.find((filter) => {
                return filter.modelName === filterModelName;
            });
            return this.convertFilterTypeToOptionValues(filterType);
        }
        return [];
    }

    getOptionValuesForMetadataType(metadataTypes, metadataTypeId) {
        const hasMetadataTypes = metadataTypes && metadataTypes.length;
        if (hasMetadataTypes) {
            const metadataType = metadataTypes.find((type) => {
                return type.id === metadataTypeId;
            });
            return this.convertMetadataValuesToOptionValues(metadataType);
        }
        return [];
    }

    getOptionValuesForProcessCategories(processCategories) {
        return processCategories ? this.convertFilterTypeToOptionValues(processCategories) : [];
    }

    handleCreateDraftClick(processCategoryKey, activityId, accessToken) {
        return this.props.createDraftActivity(activityId, accessToken).then((draftActivity) => {
            if (draftActivity && draftActivity.id) {
                this.props.fetchActivities(processCategoryKey, this.props.location.search, accessToken).then(() => {
                    this.setState({
                        redirect: `/checklist/${processCategoryKey}/${draftActivity.id}${
                            this.props.sidebarView ? "" : "/details"
                        }${this.props.location.search}`
                    });
                });
            }
        });
    }

    handleEditClick() {
        this.setState({ editable: true });
    }

    handleSaveClick(processCategoryKey, accessToken) {
        this.setState({
            editable: false,
            isLoading: true,
            loadingMessage: "Lagrer sjekkpunkt"
        });
        const activity = this.state.activity;
        activity.enterpriseTerms = this.props.selectedEnterpriseTerms; // Get updated selected enterprise terms from store
        this.props
            .updateDraftActivity(activity, accessToken)
            .then((newDraftActivity) => {
                this.props
                    .fetchActivities(processCategoryKey, this.props.location.search, accessToken)
                    .then((activity) => {
                        if (
                            newDraftActivity &&
                            newDraftActivity.id &&
                            newDraftActivity.id.toString() !== this.props.activityId
                        ) {
                            this.setState({
                                redirect: `/checklist/${processCategoryKey}/${newDraftActivity.id}${
                                    this.props.sidebarView ? "" : "/details"
                                }${this.props.location.search}` // Redirect if Id is changed
                            });
                        }
                    });
            })
            .then(() => {
                this.setState({
                    isLoading: false,
                    loadingMessage: ""
                });
            });
    }

    handlePublishClick(processCategoryKey, accessToken) {
        this.setState({
            editable: false,
            isLoading: true,
            loadingMessage: "Publiserer sjekkpunkt"
        });
        this.props
            .updatePublishedActivity(this.state.activity, accessToken)
            .then((newPublishedActivity) => {
                this.props
                    .fetchActivities(processCategoryKey, this.props.location.search, accessToken)
                    .then((activity) => {
                        if (
                            newPublishedActivity !== null &&
                            newPublishedActivity.id !== null &&
                            newPublishedActivity.id.toString() !== this.props.activityId
                        ) {
                            this.setState({
                                redirect: `/checklist/${processCategoryKey}/${newPublishedActivity.id}${
                                    this.props.sidebarView ? "" : "/details"
                                }${this.props.location.search}`
                            }); // Redirect if Id is changed
                        }
                    });
            })
            .then(() => {
                this.setState({
                    isLoading: false,
                    loadingMessage: ""
                });
            });
    }

    renderActionButton(processCategoryKey, activityId, theme) {
        const status = this.props.activity.status;
        const accessToken =
            this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token
                ? this.props.oidc.user.access_token
                : null;
        if (status === "Published" && accessToken) {
            return (
                <Button
                    type="button"
                    onClick={() => this.handleCreateDraftClick(processCategoryKey, activityId, accessToken)}
                    color="primary"
                    content="Lag kladd"
                    theme={theme}
                />
            );
        } else if (status === "Draft" && accessToken) {
            return this.state.editable ? (
                <Button
                    type="button"
                    onClick={() => this.handleSaveClick(processCategoryKey, accessToken)}
                    color="primary"
                    content="Lagre kladd"
                    theme={theme}
                />
            ) : (
                <React.Fragment>
                    <Button
                        type="button"
                        onClick={() => this.handleEditClick()}
                        className={style.warningButton}
                        content="Rediger"
                        theme={theme}
                    />
                    <Button
                        type="button"
                        onClick={() => this.handlePublishClick(processCategoryKey, accessToken)}
                        color="primary"
                        content="Publiser"
                        theme={theme}
                    />
                </React.Fragment>
            );
        } else {
            return "";
        }
    }
    renderNewCheckpoint(theme) {
        const accessToken = this.props.oidc && this.props.oidc.user && this.props.oidc.user.access_token;
        if (accessToken) {
            return (
                <Button theme={theme}>
                    <Link
                        className={style.btnAdd}
                        title="Opprett undersjekkpunkt"
                        to={{
                            pathname: "/create-activity",
                            state: { parentReferenceId: this.props.activity.referenceId }
                        }}
                    >
                        Opprett undersjekkpunkt
                    </Link>
                </Button>
            );
        }
        return "";
    }

    updateActivityStateField(fieldName, value, dataType) {
        if (dataType === "number") {
            value = parseInt(value);
        }
        this.setState({
            activity: {
                ...this.state.activity,
                [fieldName]: value
            }
        });
    }

    addActivityMetadataStateField(metadataItem) {
        const metadataItems = this.state.activity.metadata;

        metadataItems.push(metadataItem);

        this.setState({
            activity: {
                ...this.state.activity,
                metadata: metadataItems
            }
        });
    }

    isMatchingMetadataItem(metadataItem, metadataItemToMatch) {
        const metadataTypeIdHasMatch =
            metadataItem?.metadataTypeId &&
            metadataItemToMatch?.metadataTypeId &&
            metadataItem?.metadataTypeId === metadataItemToMatch?.metadataTypeId;
        if (metadataTypeIdHasMatch) {
            const metadataIdHasMatch =
                metadataItem.metadataId &&
                metadataItemToMatch.metadataId &&
                metadataItem.metadataId === metadataItemToMatch.metadataId;
            const tempMetadataIdHasMatch =
                metadataItem.tempMetadataId &&
                metadataItemToMatch.tempMetadataId &&
                metadataItem.tempMetadataId === metadataItemToMatch.tempMetadataId;
            const metadataValueHasMatch =
                metadataItem.metadataValue &&
                metadataItemToMatch.metadataValue &&
                metadataItem.metadataValue === metadataItemToMatch.metadataValue;
            return metadataIdHasMatch || tempMetadataIdHasMatch || metadataValueHasMatch;
        } else {
            return false;
        }
    }

    findIndexForMetadataItem(metadataItems, metadataItemToMatch) {
        return metadataItems.findIndex((metadataItem) => {
            return this.isMatchingMetadataItem(metadataItem, metadataItemToMatch);
        });
    }

    updateActivityMetadataStateFieldValue(metadataItem, updatedMetadataItemProps) {
        const metadataItems = this.state.activity.metadata;
        const metadataItemIndex = this.findIndexForMetadataItem(metadataItems, metadataItem);
        if (metadataItemIndex !== -1) {
            metadataItems[metadataItemIndex] = {
                ...updatedMetadataItemProps
            };

            this.setState({
                activity: {
                    ...this.state.activity,
                    metadata: metadataItems
                }
            });
        }
    }

    removeActivityMetadataStateFieldValue(metadataItem) {
        const metadataItems = this.state.activity.metadata;
        const metadataItemIndex = this.findIndexForMetadataItem(metadataItems, metadataItem);

        if (metadataItemIndex > -1) {
            metadataItems.splice(metadataItemIndex, 1);
        }

        this.setState({
            activity: {
                ...this.state.activity,
                metadata: metadataItems
            }
        });
    }

    toggleExpandEnterpriseTermsClick() {
        this.setState({
            enterPriseTermsIsExpanded: !this.state.enterPriseTermsIsExpanded
        });
    }

    toggleExpandActionsClick() {
        this.setState({
            actionsIsExpanded: !this.state.actionsIsExpanded
        });
    }

    toggleExpandLawReferencesClick() {
        this.setState({
            lawReferencesIsExpanded: !this.state.lawReferencesIsExpanded
        });
    }

    getActionTypeFromActionTypeCode(selectedActionTypeCode) {
        const selectedAction =
            this.props.actions && this.props.actions.length
                ? this.props.actions.find((availableAction) => {
                      return availableAction.actionTypeCode === selectedActionTypeCode;
                  })
                : null;
        return selectedAction.actionType;
    }

    updateActionsList(property, value, index) {
        let actions = this.state.activity.actions;
        if (property === "actionTypeCode") {
            actions[index].actionTypeCode = value;
            actions[index].actionType = this.getActionTypeFromActionTypeCode(value);
        } else if (property === "actionValue") {
            actions[index].actionValue = value === "true" ? true : false;
        } else {
            actions[index][property] = value;
        }
        this.setState(
            {
                activity: {
                    ...this.state.activity,
                    actions: actions
                }
            },
            () => {
                this.validateActions();
            }
        );
    }

    removeActionFromList(index) {
        let actions = this.state.activity.actions;
        actions.splice(index, 1);
        this.setState({
            activity: {
                ...this.state.activity,
                actions: actions
            }
        });
    }

    getActionsWithEmptyValues(actions) {
        let actionsWithEmptyValues = {};
        actions.forEach((action, actionIndex) => {
            if (!action.actionType || !action.actionTypeCode || action.actionValue === "notSelected") {
                actionsWithEmptyValues[actionIndex] = {};
                if (!action.actionType || !action.actionTypeCode) {
                    actionsWithEmptyValues[actionIndex].actionType = true;
                }
                if (action.actionValue === "notSelected") {
                    actionsWithEmptyValues[actionIndex].actionValue = true;
                }
            }
        });
        return actionsWithEmptyValues;
    }

    validateActions() {
        let actions = this.state.activity.actions;
        const actionsWithEmptyValues = this.getActionsWithEmptyValues(actions);
        this.setState({
            actionsWithEmptyValues
        });
    }

    addNewActionToList() {
        let actions = this.state.activity.actions;
        const actionsWithEmptyValues = this.getActionsWithEmptyValues(actions);
        if (Object.keys(actionsWithEmptyValues).length) {
            this.setState({
                actionsWithEmptyValues
            });
        } else {
            actions.push({
                actionType: null,
                actionTypeCode: null,
                actionValue: "notSelected"
            });
            this.setState({
                activity: {
                    ...this.state.activity,
                    actions: actions,
                    actionsWithEmptyValues: null
                }
            });
        }
    }

    renderActionsList(selectedActions, availableActions, editable, theme) {
        const actionsListElements =
            selectedActions && selectedActions.length
                ? selectedActions.map((action, actionIndex) => {
                      const actionValueHasErrors =
                          this.state.actionsWithEmptyValues &&
                          this.state.actionsWithEmptyValues[actionIndex] &&
                          this.state.actionsWithEmptyValues[actionIndex].actionValue;
                      const actionTypeHasErrors =
                          this.state.actionsWithEmptyValues &&
                          this.state.actionsWithEmptyValues[actionIndex] &&
                          this.state.actionsWithEmptyValues[actionIndex].actionType;
                      const listElement = editable ? (
                          <Paper>
                              <div className={style.inlineInputs}>
                                  <Select
                                      id={`activityDetails-Actions-actionValue-${actionIndex}`}
                                      label="Utfall"
                                      onChange={(event) =>
                                          this.updateActionsList("actionValue", event.target.value, actionIndex)
                                      }
                                      value={action.actionValue ? action.actionValue : "false"}
                                      options={[
                                          { key: "Ja", value: "true" },
                                          { key: "Nei", value: "false" }
                                      ]}
                                      contentOnly={!this.state.editable}
                                      placeholder="Velg utfall"
                                      placeholderValue="notSelected"
                                      keyAsContent={true}
                                      hasErrors={actionValueHasErrors}
                                      errorMessage={actionValueHasErrors ? "Påkrevd" : ""}
                                      theme={theme}
                                  />
                                  <Select
                                      id={`activityDetails-Actions-actionType-${actionIndex}`}
                                      label="Utfallstype"
                                      onChange={(event) =>
                                          this.updateActionsList("actionTypeCode", event.target.value, actionIndex)
                                      }
                                      value={action.actionTypeCode}
                                      options={this.convertActionsTypeToOptionValues(availableActions)}
                                      contentOnly={!this.state.editable}
                                      placeholder="Velg utfallstype"
                                      keyAsContent={true}
                                      hasErrors={actionTypeHasErrors}
                                      errorMessage={actionTypeHasErrors ? "Påkrevd" : ""}
                                      theme={theme}
                                  />
                                  <Button
                                      onClick={(event) => this.removeActionFromList(actionIndex)}
                                      type="button"
                                      id={`activityDetails-removeAction-${actionIndex}`}
                                      size="small"
                                      content="Fjern"
                                      theme={theme}
                                  />
                              </div>

                              <span>Forslag til utfallstekst</span>

                              <InputField
                                  elementKey={`activityDetails-Action-TextTitleNb-${actionIndex}`}
                                  onChange={(event) =>
                                      this.updateActionsList("titleNb", event.target.value, actionIndex)
                                  }
                                  id={`activityDetails-Action-TextTitleNb-${actionIndex}`}
                                  label="Utfall tittel bokmål"
                                  value={action.titleNb}
                                  theme={theme}
                              />
                              <InputField
                                  elementKey={`activityDetails-Action-TextDescriptionNb-${actionIndex}`}
                                  onChange={(event) =>
                                      this.updateActionsList("descriptionNb", event.target.value, actionIndex)
                                  }
                                  id={`activityDetails-Action-TextDescriptionNb-${actionIndex}`}
                                  label="Utfallstekst bokmål"
                                  value={action.descriptionNb}
                                  theme={theme}
                              />
                              <InputField
                                  elementKey={`activityDetails-Action-TextTitleNn-${actionIndex}`}
                                  onChange={(event) =>
                                      this.updateActionsList("titleNn", event.target.value, actionIndex)
                                  }
                                  id={`activityDetails-Action-TextTitleNn-${actionIndex}`}
                                  label="Utfall tittel nynorsk"
                                  value={action.titleNn}
                                  theme={theme}
                              />
                              <InputField
                                  elementKey={`activityDetails-Action-TextDescriptionNn-${actionIndex}`}
                                  onChange={(event) =>
                                      this.updateActionsList("descriptionNn", event.target.value, actionIndex)
                                  }
                                  id={`activityDetails-Action-TextDescriptionNb-${actionIndex}`}
                                  label="Utfallstekst nynorsk"
                                  value={action.descriptionNn}
                                  theme={theme}
                              />
                          </Paper>
                      ) : (
                          <div key={action.id}>
                              {action.actionValue.toString() === "false" ? "Hvis nei " : "Hvis ja "}-{" "}
                              {action.actionType}
                          </div>
                      );
                      return <ListItem key={action.id}>{listElement}</ListItem>;
                  })
                : "";
        return (
            <React.Fragment>
                <List listStyle="none" compact>
                    {actionsListElements}
                </List>
                {editable ? (
                    <Button
                        onClick={() => this.addNewActionToList()}
                        type="button"
                        id="activityDetails-newAction"
                        content="Legg til utfall"
                        theme={theme}
                    />
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    }

    updateLawReferencesList(property, value, index) {
        let lawReferences = this.state.activity.lawReferences;
        lawReferences[index][property] = value;
        this.setState({
            activity: {
                ...this.state.activity,
                lawReferences: lawReferences
            }
        });
    }

    removeLawReferenceFromList(index) {
        let lawReferences = this.state.activity.lawReferences;
        lawReferences.splice(index, 1);
        this.setState({
            activity: {
                ...this.state.activity,
                lawReferences: lawReferences
            }
        });
    }

    addNewLawReferenceToList() {
        let lawReferences = this.state.activity.lawReferences;
        lawReferences.push({
            lawReferenceDescription: null,
            lawReferenceUrl: null
        });
        this.setState({
            activity: {
                ...this.state.activity,
                lawReferences: lawReferences
            }
        });
    }

    renderLawReferencesList(lawReferences, editable, theme) {
        const lawReferencesListElements =
            lawReferences && lawReferences.length
                ? lawReferences.map((lawReference, lawReferenceIndex) => {
                      const listElement = editable ? (
                          <div className={style.inlineInputs}>
                              <InputField
                                  elementKey={`activityDetails-LawReference-description-${lawReferenceIndex}`}
                                  onChange={(event) =>
                                      this.updateLawReferencesList(
                                          "lawReferenceDescription",
                                          event.target.value,
                                          lawReferenceIndex
                                      )
                                  }
                                  id={`activityDetails-LawReference-description-${lawReferenceIndex}`}
                                  label="Beskrivelse"
                                  value={lawReference.lawReferenceDescription}
                                  theme={theme}
                              />
                              <InputField
                                  elementKey={`activityDetails-LawReference-description-${lawReferenceIndex}`}
                                  onChange={(event) =>
                                      this.updateLawReferencesList(
                                          "lawReferenceUrl",
                                          event.target.value,
                                          lawReferenceIndex
                                      )
                                  }
                                  id={`activityDetails-LawReference-url-${lawReferenceIndex}`}
                                  label="Lenke"
                                  value={lawReference.lawReferenceUrl}
                                  theme={theme}
                              />
                              <Button
                                  type="button"
                                  onClick={(event) => this.removeLawReferenceFromList(lawReferenceIndex)}
                                  id={`activityDetails-removeLawReference-${lawReferenceIndex}`}
                                  size="small"
                                  content="Fjern"
                                  theme={theme}
                              />
                          </div>
                      ) : (
                          <div>
                              <a
                                  href={lawReference.lawReferenceUrl}
                                  rel="noreferrer"
                                  target="_blank"
                                  aria-label={`${lawReference.lawReferenceDescription} (åpnes i nytt vindu)`}
                              >
                                  {lawReference.lawReferenceDescription}{" "}
                              </a>
                          </div>
                      );
                      return <ListItem key={lawReferenceIndex}>{listElement}</ListItem>;
                  })
                : "";
        return (
            <React.Fragment>
                <List listStyle="none" compact>
                    {lawReferencesListElements}
                </List>
                {editable ? (
                    <Button
                        type="button"
                        onClick={() => this.addNewLawReferenceToList()}
                        id="activityDetails-newLawReference"
                        content="Legg til lovhjemmel"
                        theme={theme}
                    />
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    }

    renderEditableMetadataTypes(metadataType, metadataTypeIndex, currentMetadataValues, editable, isLoggedIn) {
        return (
            <div key={metadataTypeIndex} className={style.activityDetailsField}>
                {editable ? (
                    <React.Fragment>
                        {metadataType.viewType === "Dropdownlist"
                            ? this.renderDynamicMetadataSelectField(
                                  metadataType.id,
                                  metadataType.name,
                                  "Velg fra listen",
                                  metadataType.maxCountOfValues
                              )
                            : ""}
                        {metadataType.viewType === "Textbox"
                            ? this.renderDynamicMetadataInputField(
                                  metadataType.id,
                                  metadataType.name,
                                  metadataType.maxCountOfValues
                              )
                            : ""}
                        {metadataType.viewType === "Checkbox"
                            ? this.renderDynamicMetadataCheckbox(
                                  metadataType.id,
                                  metadataType.name,
                                  metadataType.maxCountOfValues
                              )
                            : ""}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Header content={metadataType.name} size={4} />
                        {currentMetadataValues?.map((m, mIndex) => {
                            return <p key={mIndex}>{m.metadataValue}</p>;
                        })}
                    </React.Fragment>
                )}
            </div>
        );
    }

    renderMetadataList(metadataTypes, activityMetadata, editable, isLoggedIn) {
        const metadataTypesElement =
            metadataTypes && metadataTypes.length
                ? metadataTypes.map((metadataType, metadataTypeIndex) => {
                      const currentMetadataValues =
                          activityMetadata && activityMetadata.length
                              ? activityMetadata.filter(function (m) {
                                    return m.metadataTypeId === metadataType.id;
                                })
                              : null;

                      return isLoggedIn || editable ? (
                          this.renderEditableMetadataTypes(
                              metadataType,
                              metadataTypeIndex,
                              currentMetadataValues,
                              editable,
                              isLoggedIn
                          )
                      ) : (
                          <div key={metadataTypeIndex} className={style.activityDetailsField}>
                              <Header content={metadataType.name} size={4} />
                              {currentMetadataValues?.map((m) => {
                                  return <p key={`metadataValue-${m.metadataId}`}>{m.metadataValue}</p>;
                              })}
                          </div>
                      );
                  })
                : "";

        return <React.Fragment>{metadataTypesElement}</React.Fragment>;
    }

    handleImageFileInputChange(files) {
        const file = files[0];
        if (file) {
            const reader = new FileReader();
            const validFileTypes = ["image/jpeg", "image/png", "image/gif", "image/webp", "image/svg+xml"];
            const maxFileSize = 10 * 1024 * 1024; // 10 MB
            const isValidFileType = validFileTypes.includes(file.type);

            if (!isValidFileType) {
                this.setState({
                    imageFileErrorMessage: "Bildefilen må være av typen: jpeg, png, gif, webp eller svg"
                });
                return;
            }
            if (file.size > maxFileSize) {
                this.setState({
                    imageFileErrorMessage: "Bildefilen kan ikke være større enn 20 MB"
                });
                return;
            }

            if (this.state.imageFileErrorMessage?.length) {
                this.setState({
                    imageFileErrorMessage: null
                });
            }

            this.setState({
                selectedImageFileName: file.name
            });
            reader.readAsDataURL(file);
            reader.onload = function (evt) {
                let fileContent = evt.target.result;
                this.updateActivityStateField("image", fileContent);
            }.bind(this);
            reader.onerror = function (evt) {
                console.warn("error reading file");
            };
        }
        this.setState({
            hasSelectedImageFile: true
        });
    }

    renderSelectField(filterModelName, fieldName, label, placeholder) {
        const isLoggedIn = this.props.oidc?.user?.access_token;
        const options = this.getOptionValuesForFilterType(this.props.filters, filterModelName);
        const valueId = this.state.activity[fieldName];
        const value = options?.find((o) => o.value === valueId)?.key ?? valueId;

        return isLoggedIn && this.state.editable ? (
            <React.Fragment>
                <Select
                    id={`activityDetails-${filterModelName}`}
                    name={fieldName}
                    label={label}
                    onChange={(event) => this.updateActivityStateField(fieldName, event.target.value)}
                    value={valueId || ""}
                    options={options}
                    keyAsContent={true}
                    placeholder={placeholder || ""}
                />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Header content={label} size={4} />
                <p>{value}</p>
            </React.Fragment>
        );
    }

    renderDynamicMetadataSelectField(metadataTypeId, label, placeholder, maxCountOfValues) {
        const isLoggedIn = this.props.oidc?.user?.access_token;
        const metadataItems = this.state.activity.metadata.filter((m) => m.metadataTypeId === metadataTypeId);
        const options = this.getOptionValuesForMetadataType(this.props.metadataTypes, metadataTypeId);
        const hasMultipleValues = maxCountOfValues === 0 || maxCountOfValues > 1;
        const selectedValue = metadataItems?.length
            ? hasMultipleValues
                ? metadataItems?.map((value) => value.metadataId)
                : metadataItems?.[0]?.metadataId.toString()
            : "";
        return isLoggedIn && this.state.editable ? (
            <React.Fragment>
                <div className={style.inlineInputs}>
                    <Select
                        id={`activityDetails-${metadataTypeId}`}
                        label={`${label}${
                            maxCountOfValues > 1
                                ? ` (${metadataItems?.length} av maksimalt ${maxCountOfValues} er valgt)`
                                : ""
                        }`}
                        onChange={(event) => {
                            if (hasMultipleValues) {
                                const metadataId = event;
                                const isChecked = metadataItems?.some((value) => value?.metadataId === metadataId);
                                const metadataItem = { metadataTypeId, metadataId: parseInt(metadataId) };
                                isChecked
                                    ? this.removeActivityMetadataStateFieldValue(metadataItem)
                                    : this.addActivityMetadataStateField(metadataItem);
                            } else {
                                const oldMetadataItem = metadataItems?.[0];
                                const newMetadataItem = { metadataTypeId, metadataId: parseInt(event.target.value) };
                                if (oldMetadataItem) {
                                    this.updateActivityMetadataStateFieldValue(oldMetadataItem, newMetadataItem);
                                } else {
                                    this.addActivityMetadataStateField(newMetadataItem);
                                }
                            }
                        }}
                        defaultValue={selectedValue}
                        options={options}
                        multiple={hasMultipleValues}
                        keyAsContent={true}
                        placeholder={placeholder || ""}
                    />
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Header content={label} size={4} />
                <p>{selectedValue}</p>
            </React.Fragment>
        );
    }

    getDynamicMetadataInputFieldKey(metadataItem) {
        return Number.isInteger(metadataItem?.metadataId) ? metadataItem.metadataId : metadataItem.tempMetadataId;
    }

    renderDynamicMetadataInputField(metadataTypeId, label, maxCountOfValues) {
        const isLoggedIn = this.props.oidc?.user?.access_token;
        const metadataItemsForMetadataType = this.state.activity.metadata.filter(
            (m) => m.metadataTypeId === metadataTypeId
        );
        const hasMultipleValues = maxCountOfValues === 0 || maxCountOfValues > 1;

        return isLoggedIn && this.state.editable ? (
            hasMultipleValues ? (
                <Fragment>
                    <Label>{label}</Label>
                    {metadataItemsForMetadataType.map((metadataItem, index) => {
                        return (
                            <div
                                className={style.inlineInputs}
                                key={`activityDetails-${metadataTypeId}-${this.getDynamicMetadataInputFieldKey(
                                    metadataItem
                                )}`}
                            >
                                <InputField
                                    elementKey={`activityDetails-${metadataTypeId}-${this.getDynamicMetadataInputFieldKey(
                                        metadataItem
                                    )}-input`}
                                    onChange={(event) => {
                                        const newMetadataItem = {
                                            metadataTypeId,
                                            metadataValue: event.target.value,
                                            tempMetadataId: metadataItem?.tempMetadataId,
                                            metadataId: metadataItem?.metadataId
                                        };
                                        this.updateActivityMetadataStateFieldValue(metadataItem, newMetadataItem);
                                    }}
                                    id={`activityDetails-${metadataTypeId}-${index}`}
                                    aria-label={label}
                                    defaultValue={metadataItem.metadataValue}
                                />
                                <Button
                                    onClick={() => {
                                        this.removeActivityMetadataStateFieldValue(metadataItem);
                                    }}
                                >
                                    Slett
                                </Button>
                            </div>
                        );
                    })}

                    <Button
                        onClick={() =>
                            this.addActivityMetadataStateField({
                                metadataTypeId,
                                tempMetadataId: uuidv4()
                            })
                        }
                    >
                        Legg til
                    </Button>
                </Fragment>
            ) : (
                <InputField
                    elementKey={`activityDetails-${metadataTypeId}`}
                    onChange={(event) => {
                        if (metadataItemsForMetadataType?.length) {
                            const metadataItem = metadataItemsForMetadataType[0];
                            const newMetadataItem = {
                                metadataTypeId,
                                metadataValue: event.target.value,
                                tempMetadataId: uuidv4(),
                                metadataId: metadataItemsForMetadataType[0]?.metadataId
                            };
                            this.updateActivityMetadataStateFieldValue(metadataItem, newMetadataItem);
                        } else {
                            this.addActivityMetadataStateField({
                                metadataTypeId,
                                tempMetadataId: uuidv4(),
                                metadataValue: event.target.value
                            });
                        }
                    }}
                    id={`activityDetails-${metadataTypeId}`}
                    label={label}
                    value={metadataItemsForMetadataType[0]?.metadataValue || ""}
                />
            )
        ) : (
            <React.Fragment>
                <Header content={label} size={4} />
                <p>{metadataItemsForMetadataType[0]}</p>
            </React.Fragment>
        );
    }

    renderDynamicMetadataCheckbox(metadataTypeId, label, maxCountOfValues) {
        const isLoggedIn = this.props.oidc?.user?.access_token;
        const metadataItems = this.state.activity.metadata.filter((m) => m.metadataTypeId === metadataTypeId);
        const options = this.getOptionValuesForMetadataType(this.props.metadataTypes, metadataTypeId);
        const hasMultipleValues = maxCountOfValues === 0 || maxCountOfValues > 1;

        const ListElementTag = maxCountOfValues === 1 ? CheckBoxList : RadioButtonList;
        return isLoggedIn && this.state.editable ? (
            <ListElementTag
                compact
                legend={`${label}${
                    maxCountOfValues > 1 ? ` (${metadataItems?.length} av maksimalt ${maxCountOfValues} er valgt)` : ""
                }`}
            >
                {options.map((metadataItemOption, mIndex) => {
                    const metadataId = metadataItemOption.value;
                    const isChecked = metadataItems?.some((value) => value?.metadataId === metadataId);
                    return hasMultipleValues ? (
                        <CheckBoxListItem
                            key={`activityDetailsCheckbox-${metadataTypeId}-${mIndex}`}
                            onChange={() => {
                                const metadataItem = { metadataTypeId, metadataId };
                                isChecked
                                    ? this.removeActivityMetadataStateFieldValue(metadataItem)
                                    : this.addActivityMetadataStateField(metadataItem);
                            }}
                            checked={isChecked}
                            inputValue={metadataId.toString()}
                            name={`activityDetailsCheckbox-${metadataTypeId}`}
                            compact
                            id={`activityDetailsCheckbox-${metadataTypeId}-${mIndex}`}
                        >
                            {metadataItemOption.key}
                        </CheckBoxListItem>
                    ) : (
                        <RadioButtonListItem
                            key={`activityDetailsCheckbox-${metadataTypeId}-${mIndex}`}
                            onChange={() => {
                                const oldMetadataItem = metadataItems?.[0];
                                const newMetadataItem = { metadataTypeId, metadataId };
                                if (oldMetadataItem) {
                                    this.updateActivityMetadataStateFieldValue(oldMetadataItem, newMetadataItem);
                                } else {
                                    this.addActivityMetadataStateField(newMetadataItem);
                                }
                            }}
                            checked={isChecked}
                            inputValue={metadataId.toString()}
                            name={`activityDetailsCheckbox-${metadataTypeId}`}
                            compact
                            id={`activityDetailsCheckbox-${metadataTypeId}-${mIndex}`}
                        >
                            {metadataItemOption.key}
                        </RadioButtonListItem>
                    );
                })}
            </ListElementTag>
        ) : (
            <React.Fragment>
                <Header content={label} size={4} />
                {metadataItems.map((m, mIndex) => {
                    return <p>{m}</p>;
                })}
            </React.Fragment>
        );
    }

    renderUserFeedbackEmailAddress() {
        return getEnvironmentVariable("userFeedbackEmail");
    }

    renderEmailSubject(activity) {
        return `Sjekkliste tilbakemelding for punkt : ${activity?.referenceId} (${this.props.processCategoryKey}/${this.props.activityId})`;
    }

    renderUserFeedbackEmail() {
        var userFeedbackEmail = getEnvironmentVariable("userFeedbackEmail");
        return (
            <a href={`mailto:${userFeedbackEmail}?subject=${this.renderEmailSubject(this.state.activity)}`}>
                Send oss dine innspill her
            </a>
        );
    }

    showDeleteParentReferenceIdModal() {
        this.setState({
            showDeleteParentReferenceIdModal: true
        });
    }

    updateMetadata(metadataObject) {
        // TODO: Make dynamic
        if (!metadataObject.metadataTypeId) {
            console.warn("Missing property: metadataTypeId");
            return;
        }
        const metadata = this.state.activity?.metadata || [];
        const metadataTypeId = metadataObject.metadataTypeId;

        if (!metadataObject.metadataId && !metadataObject.metadataValue?.length) {
            this.removeMetadata(metadataTypeId);
            return;
        }

        const metadataIndex = metadata.findIndex((metadataItem) => {
            return metadataItem.metadataTypeId === metadataTypeId;
        });

        if (metadataIndex !== -1) {
            metadata[metadataIndex] = metadataObject;
        } else {
            metadata.push(metadataObject);
        }

        this.setState({
            activity: {
                ...this.state.activity,
                metadata
            }
        });
    }

    getMetadataForMetadataType(metadataTypeId) {
        const metadata = this.state.activity?.metadata;
        const hasMetadata = Array.isArray(metadata) && metadata.length;
        return hasMetadata
            ? metadata.find((metadataObject) => {
                  return metadataObject.metadataTypeId === metadataTypeId;
              })
            : null;
    }

    removeMetadata(metadataTypeId) {
        const metadata = this.state.activity?.metadata || [];
        const metadataIndex = metadata.findIndex((metadataItem) => {
            return metadataItem.metadataTypeId === metadataTypeId;
        });
        if (metadataIndex !== -1) {
            metadata.splice(metadataIndex, 1);
            this.setState({
                activity: {
                    ...this.state.activity,
                    metadata
                }
            });
        }
    }

    showFilter(filters, filterModelName) {
        const showOnDetailPage =
            filters?.length &&
            filters.find((filter) => {
                return filter.modelName === filterModelName && filter.showOnDetailPage !== false;
            });
        return showOnDetailPage;
    }

    render() {
        const theme = getEnvironmentVariable("theme");
        const infoBoxStyle = {
            backgroundColor: getThemePaletteBackgroundColor("info")
        };
        const processCategoryKey = this.props.processCategoryKey;
        const activityId = this.props.activityId;
        const isLoggedIn = this.props.oidc?.user?.access_token ? true : false;

        const userFeedbackLabel = getEnvironmentVariable("userFeedbackLabelActivity", {
            mailToAddress: getEnvironmentVariable("userFeedbackEmail"),
            mailToSubject: this.renderEmailSubject(this.state.activity)
        });
        const userFeedbackHtml = renderUserFeedbackHtml(userFeedbackLabel, false);

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        } else if (this.state.activity) {
            return (
                <div
                    ref={this.setWrapperRef}
                    className={`${style.activityDetailsContainer} ${this.props.sidebarView ? style.sidebarView : ""}`}
                >
                    {this.state.isLoading ? (
                        <div className={style.loadingAnimationContainer}>
                            <img src={loadingAnimation} alt="laster innhold" />
                            <span>{this.state.loadingMessage}</span>
                        </div>
                    ) : (
                        ""
                    )}
                    {this.props.sidebarView ? (
                        <div className={style.activityDetailsHeader}>
                            <Link
                                to={`/checklist/${processCategoryKey}/${activityId}/details`}
                                className={style.activityDetailsHeaderTitle}
                            >
                                Se detaljer
                            </Link>
                            <Link
                                aria-label="Lukk detaljer"
                                to={`/checklist/${processCategoryKey}${this.props.location.search}`}
                                className={style.activityDetailsHeaderClose}
                            >
                                <FontAwesomeIcon icon="times" className={style.close} />
                            </Link>
                        </div>
                    ) : (
                        <div className={style.activityDetailsHeaderMain}>
                            <Link
                                to={`/checklist/${processCategoryKey}${this.props.location.search}`}
                                className={style.activityDetailsHeaderClose}
                            >
                                Tilbake til sjekklisten
                            </Link>
                        </div>
                    )}

                    {this.state.showDeleteParentReferenceIdModal ? this.renderDeleteParentResponseModal(theme) : ""}

                    {/* ********* Nytt undersjekkpunkt ********** */}

                    <div
                        className={
                            isLoggedIn
                                ? `${style.activityDetailsContent} ${style.isLoggedIn}`
                                : style.activityDetailsContent
                        }
                    >
                        {this.renderNewCheckpoint(theme)}
                        {this.props.activity.status === "Draft" && this.props.activity.publishedActivity_Id ? (
                            <p>
                                Sjekkpunkt som er kladd og tidligere har vært publisert vil ikke kunne vise underpunkt.
                                Disse vil vises når sjekkpunktet blir publisert.
                            </p>
                        ) : (
                            ""
                        )}

                        {/*  ********* ID ********** */}

                        {this.props.sidebarView ? (
                            ""
                        ) : (
                            <div className={style.activityDetailsField}>
                                <Header content="ID" size={4} />
                                <p>{this.state.activity.referenceId}</p>
                            </div>
                        )}

                        {/* ********* Underpunkt endres til hovedpunkt ********** */}

                        {this.state.editable && this.hasParentReferenceId() ? (
                            <Button
                                type="button"
                                onClick={() => this.showDeleteParentReferenceIdModal()}
                                content="Endre til hovedpunkt"
                                color="primary"
                            />
                        ) : (
                            ""
                        )}

                        {/* ********* Tema ********** */}

                        {(isLoggedIn || (this.state.activity?.category?.length && !this.props.sidebarView)) &&
                        this.showFilter(this.props.filters, "Category") ? (
                            <div className={style.activityDetailsField}>
                                <Header content="Tema" size={4} />
                                <p>{this.state.activity.category}</p>
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Sjekkpunkt tittel Bokmål/Nynorsk ********** */}

                        {isLoggedIn ? (
                            this.state.editable ? (
                                <React.Fragment>
                                    <div className={style.activityDetailsField}>
                                        <InputField
                                            elementKey="activityDetails-Name"
                                            onChange={(event) =>
                                                this.updateActivityStateField("name", event.target.value)
                                            }
                                            id="activityDetails-Name"
                                            label="Sjekkpunkt"
                                            value={this.state.activity?.name || ""}
                                        />
                                    </div>
                                    <div className={style.activityDetailsField}>
                                        <InputField
                                            elementKey={`activityDetails-nameNynorsk`}
                                            onChange={(event) =>
                                                this.updateActivityStateField("nameNynorsk", event.target.value)
                                            }
                                            id="activityDetails-NameNynorsk"
                                            label="Sjekkpunkt nynorsk"
                                            value={this.state.activity?.nameNynorsk || ""}
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className={style.activityDetailsField}>
                                    <Header content="Sjekkpunkt" size={4} />
                                    <p>{this.state.activity.name}</p>
                                    <Header content="Sjekkpunkt nynorsk" size={4} />
                                    <p>{this.state.activity.nameNynorsk}</p>
                                </div>
                            )
                        ) : getActivityName(this.state.activity, this.props.selectedLanguage) ? (
                            <div className={style.activityDetailsField}>
                                <Header content="Sjekkpunkt" size={4} />
                                <p>{getActivityName(this.state.activity, this.props.selectedLanguage)}</p>
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Hjelpetekst - beskrivelse ********** */}

                        {isLoggedIn ? (
                            this.state.editable ? (
                                <React.Fragment>
                                    <div className={style.activityDetailsField}>
                                        <InputField
                                            elementKey={`activityDetails-description`}
                                            onChange={(event) =>
                                                this.updateActivityStateField("description", event.target.value)
                                            }
                                            id="activityDetails-Description"
                                            label="Hjelpetekst"
                                            value={this.state.activity?.description || ""}
                                        />
                                    </div>
                                    <div className={style.activityDetailsField}>
                                        <InputField
                                            elementKey={`activityDetails-descriptionNynorsk`}
                                            onChange={(event) =>
                                                this.updateActivityStateField("descriptionNynorsk", event.target.value)
                                            }
                                            id="activityDetails-DescriptionNynorsk"
                                            label="Hjelpetekst nynorsk"
                                            value={this.state.activity?.descriptionNynorsk || ""}
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className={style.activityDetailsField}>
                                    <Header content="Hjelpetekst" size={4} />
                                    <p>{this.state.activity.description}</p>
                                    <Header content="Hjelpetekst nynorsk" size={4} />
                                    <p>{this.state.activity.descriptionNynorsk}</p>
                                </div>
                            )
                        ) : getActivityDescription(this.state.activity, this.props.selectedLanguage)?.length ? (
                            <div className={style.activityDetailsField}>
                                <Header content="Hjelpetekst" size={4} />
                                <p>{getActivityDescription(this.state.activity, this.props.selectedLanguage)}</p>
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Type sjekk ********** */}

                        {(isLoggedIn || this.state.activity?.activityType?.length) &&
                        this.showFilter(this.props.filters, "ActivityType") ? (
                            <div className={style.activityDetailsField}>
                                {this.renderSelectField("ActivityType", "activityType", "Type sjekk")}
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Illustrasjon ********** */}

                        {this.state.editable ? (
                            <div className={style.activityDetailsField}>
                                <DragAndDropFileInput
                                    id="imageFile"
                                    label="Illustrasjon"
                                    buttonContent="Velg bildefil (Maks filstørrelse er 20 MB)"
                                    buttonColor="primary"
                                    selectedFileName={this.state.selectedImageFileName}
                                    onSelectChange={(event) => this.handleImageFileInputChange(event.target.files)}
                                    onDragAndDropChange={this.handleImageFileInputChange}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.imageFileErrorMessage?.length ? <p>{this.state.imageFileErrorMessage}</p> : ""}

                        {this.state.activity?.image?.length ? (
                            <div className={style.activityDetailsField}>
                                {!this.state.editable ? <Header content="Illustrasjon" size={4} /> : ""}
                                <img alt={this.state.activity.imageDescription} src={this.state.activity.image} />
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Illustrasjon beskrivelse ********** */}
                        {this.state.editable ? (
                            <div className={style.activityDetailsField}>
                                <InputField
                                    elementKey={`activityDetails-imageDescription`}
                                    onChange={(event) => {
                                        this.updateActivityStateField("imageDescription", event.target.value);
                                    }}
                                    id="activityDetails-imageDescription"
                                    label="Illustrasjon beskrivelse"
                                    value={this.state.activity?.imageDescription || ""}
                                    contentOnly={!this.state.editable}
                                />
                                <span>
                                    {" "}
                                    <a
                                        key="labelLinkAltText"
                                        href="https://www.uutilsynet.no/regelverk/bilder-og-grafikk/205"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Universell utforming - Krav om bildetekst
                                    </a>
                                </span>
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Dokumentasjon ********** */}

                        {this.state.editable ? (
                            <div className={style.activityDetailsField}>
                                <label>Dokumentasjon</label>
                                <div data-color-mode="light">
                                    <MDEditor
                                        id="activityDetails-documentation"
                                        preview="edit"
                                        height={200}
                                        name="documentation"
                                        value={this.state.activity?.documentation || ""}
                                        onChange={(value) => {
                                            this.updateActivityStateField("documentation", value);
                                        }}
                                    />
                                </div>
                            </div>
                        ) : isLoggedIn || this.state.activity?.documentation?.length ? (
                            <div className={`${style.activityDetailsField}`}>
                                <Header content="Dokumentasjon" size={4} />
                                <div data-color-mode="light" style={{ fontFamily: "inherit" }}>
                                    <MDEditor.Markdown
                                        id="activityDetails-documentation-preview"
                                        source={this.state.activity?.documentation || ""}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {/* ********* Gyldig til/Fra dato ********** */}

                        {isLoggedIn ||
                        this.state.activity?.validFrom?.length ||
                        this.state.activity?.validTo?.length ? (
                            <div className={style.activityDetailsField}>
                                {this.state.editable ? (
                                    <div className={style.inlineInputs}>
                                        {isLoggedIn || this.state.activity?.validFrom?.length ? (
                                            <InputField
                                                elementKey={`activityDetails-validfrom`}
                                                onChange={(event) =>
                                                    this.updateActivityStateField(
                                                        "validFrom",
                                                        convertFromInputDateToTimestamp(event.target.value)
                                                    )
                                                }
                                                id="activityDetails-ValidFrom"
                                                label="Gyldig fra"
                                                type="date"
                                                value={
                                                    convertFromTimestampToInputDate(this.state.activity.validFrom) || ""
                                                }
                                                defaultContent="Dato er ikke angitt"
                                                max={convertFromTimestampToInputDate(this.state.activity.validTo)}
                                            />
                                        ) : (
                                            ""
                                        )}
                                        {isLoggedIn || this.state.activity?.validTo?.length ? (
                                            <InputField
                                                elementKey={`activityDetails-validTo`}
                                                onChange={(event) =>
                                                    this.updateActivityStateField(
                                                        "validTo",
                                                        convertFromInputDateToTimestamp(event.target.value)
                                                    )
                                                }
                                                id="activityDetails-ValidTo"
                                                label="Gyldig til"
                                                type="date"
                                                value={
                                                    convertFromTimestampToInputDate(this.state.activity.validTo) || ""
                                                }
                                                defaultContent="Dato er ikke angitt"
                                                min={convertFromTimestampToInputDate(this.state.activity.validFrom)}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <Header content="Gyldig fra" size={4} />
                                        <p>{convertFromTimestampToText(this.state.activity.validFrom)}</p>
                                        <Header content="Gyldig til" size={4} />
                                        <p>{convertFromTimestampToText(this.state.activity.validTo)}</p>
                                    </React.Fragment>
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Søknadstype ********** */}
                        {isLoggedIn && !this.props.sidebarView ? (
                            <div className={style.activityDetailsField}>
                                {this.state.editable ? (
                                    <Select
                                        id="activityDetails-ProcesssCategory"
                                        label={[
                                            "Søknadstype",
                                            <span key="labelLink" aria-hidden="true">
                                                {" "}
                                                (fra{" "}
                                                <a
                                                    href="https://register.geonorge.no/kodelister/byggesoknad/soknadstype"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    aria-label="kodeliste (åpnes i nytt vindu)"
                                                >
                                                    kodeliste
                                                </a>
                                            </span>,
                                            ")"
                                        ]}
                                        onChange={(event) =>
                                            this.updateActivityStateField("processCategory", event.target.value)
                                        }
                                        value={this.state.activity.processCategory}
                                        options={this.getOptionValuesForProcessCategories(this.props.processCategories)}
                                        keyAsContent={true}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <Header content="Søknadstype" size={4} />
                                        <p>{this.state.activity.processCategory}</p>
                                    </React.Fragment>
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Milepel ********** */}

                        {(isLoggedIn || this.state.activity?.milestone?.length) &&
                        this.showFilter(this.props.filters, "Milestone") ? (
                            <div className={style.activityDetailsField}>
                                {this.renderSelectField("Milestone", "milestone", "Milepel", "Velg milepel fra listen")}
                            </div>
                        ) : (
                            ""
                        )}

                        {/* Dynamiske metadata, visning */}

                        {this.renderMetadataList(
                            this.props.metadataTypes,
                            this.props.activity.metadata,
                            this.state.editable,
                            isLoggedIn
                        )}

                        {/* ********* Maskinlesbar regel ********** */}

                        {isLoggedIn || this.state.activity?.rule?.length ? (
                            <div className={style.activityDetailsField}>
                                {this.state.editable ? (
                                    <InputField
                                        elementKey={`activityDetails-rule`}
                                        onChange={(event) => this.updateActivityStateField("rule", event.target.value)}
                                        id="activityDetails-Rule"
                                        label="Maskinlesbar regel"
                                        value={this.state.activity?.rule || ""}
                                        contentOnly={!this.state.editable}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <Header content="Maskinlesbar regel" size={4} />
                                        <p>{this.state.activity.rule}</p>
                                    </React.Fragment>
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Regeltype ********** */}

                        {/* {
              isLoggedIn || this.getMetadataForMetadataType(4)?.length
                ? (
                  
                  this.state.editable ?
                  <div className={style.activityDetailsField}>
                    <Select
                      id="activityDetails-ruleType"
                      label="Regeltype"
                      onChange={event => this.updateMetadata({ metadataTypeId: 3, metadataId: parseInt(event.target.value) })}
                      options={[
                        { key: 'Innbyrdes avhengighet', value: 6 },
                        { key: 'Hierarkisk avhengighet', value: 5 },
                        { key: 'Vedleggskontroll', value: 4 },
                        { key: 'Skjemakontroll', value: 7 },
                      ]}
                      keyAsContent={true}
                      value={this.getMetadataForMetadataType(3)?.metadataId || ''}
                      placeholder="Velg regeltype fra listen"
                      contentOnly={!this.state.editable} />
                  </div>
                  :
                  <React.Fragment> 
                    <div className={style.activityDetailsField}>
                    <Header content="Regeltype" size={4}/>
                    <p>{this.getMetadataForMetadataType(3)?.metadataId || ''}</p>
                    </div>
                  </React.Fragment> 
                )
                : ''
            } */}

                        {/*  isLoggedIn || this.state.activity?.ruleSource?.length
                  ? (
                    <div className={style.activityDetailsField}>
                      <InputField
                        id="activityDetails-RuleSource"
                        onChange={event => this.updateMetadata({ metadataTypeId: 2, metadataValue: event.target.value })}
                        label="Regelkilde"
                        value={this.getMetadataForMetadataType(2)?.metadataValue}
                        contentOnly={!this.state.editable} />
                    </div>
                  )
                  : ''*/}

                        {/* ********* Kontrollert i FTB ********** */}

                        {/* {
              isLoggedIn ?
              (this.state.editable
                ? (
                  <div className={style.activityDetailsField}>
                    <label>Kontrollert i Fellestjenester bygg</label>
                    <div className={style.inlineInputs}>
                      <RadioButtonInput
                        id="fbtApproved-true"
                        onChange={() => this.updateMetadata({ metadataTypeId: 1, metadataId: 1 })}
                        checked={this.getMetadataForMetadataType(1)?.metadataId === 1}
                        name="ftbApproved"
                        inputValue="true"
                        theme={theme}>
                        <span>Ja</span>
                      </RadioButtonInput>
                      <RadioButtonInput
                        id="fbtApproved-false"
                        onChange={() => this.updateMetadata({ metadataTypeId: 1, metadataId: 2 })}
                        checked={this.getMetadataForMetadataType(1)?.metadataId === 2}
                        name="ftbApproved"
                        inputValue="false"
                        theme={theme}>
                        <span>Nei</span>
                      </RadioButtonInput>
                      <RadioButtonInput
                        id="fbtApproved-null"
                        onChange={() => this.removeMetadata(1)}
                        checked={!this.getMetadataForMetadataType(1)}
                        name="ftbApproved"
                        inputValue="null"
                        theme={theme}>
                        <span>Ikke sjekket</span>
                      </RadioButtonInput>
                    </div>
                  </div>
                )
                : this.state.activity?.ftbApproved?.length
                  ? (<div className={style.activityDetailsField}>
                    <React.Fragment> 
                      <Header content="Kontrollert i Fellestjenester bygg" size={4}/>
                      <p>{ this.getMetadataForMetadataType(1)?.metadataId
                          ? this.getMetadataForMetadataType(1)?.metadataId === 1 ? 'Ja' : 'Nei'
                          : 'Ikke sjekket'}</p>
                    </React.Fragment> 
                  </div>)
                  : ''
              ) : ''
            } */}

                        {/* ********* Rekkefølge ********** */}

                        {isLoggedIn ? (
                            <div className={style.activityDetailsField}>
                                {this.state.editable ? (
                                    <InputField
                                        elementKey={`activityDetails-orderNumber`}
                                        type="number"
                                        onChange={(event) =>
                                            this.updateActivityStateField("orderNumber", event.target.value, "number")
                                        }
                                        id="activityDetails-orderNumber"
                                        label="Rekkefølge"
                                        value={this.state.activity?.orderNumber || ""}
                                        contentOnly={!this.state.editable}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <Header content="Rekkefølge" size={4} />
                                        <p>{this.state.activity.orderNumber}</p>
                                    </React.Fragment>
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        {/* Eier */}

                        {isLoggedIn ? (
                            <div className={style.activityDetailsField}>
                                {this.state.editable ? (
                                    <InputField
                                        elementKey={`activityDetails-owner`}
                                        type="number"
                                        onChange={(event) =>
                                            this.updateActivityStateField("municipality", event.target.value.toString())
                                        }
                                        id="activityDetails-owner"
                                        label="Eier"
                                        value={this.state.activity?.municipality || ""}
                                        contentOnly={!this.state.editable}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <Header content="Eier" size={4} />
                                        <p>{this.state.activity.municipality}</p>
                                    </React.Fragment>
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Lovhjemmel ********** */}

                        {isLoggedIn || this.state.activity?.lawReferences?.length ? (
                            <div className={`${style.expandableList} ${style.activityDetailsField}`}>
                                <button
                                    type="button"
                                    aria-expanded={this.state.lawReferencesIsExpanded ? "true" : "false"}
                                    onClick={() => this.toggleExpandLawReferencesClick()}
                                    className={`${style.expandableListHeader} ${style.unstyled}`}
                                >
                                    <FontAwesomeIcon
                                        className={style.expandButton}
                                        icon={this.state.lawReferencesIsExpanded ? "chevron-down" : "chevron-right"}
                                        alt={
                                            this.state.lawReferencesIsExpanded
                                                ? "Skjul underpunkter"
                                                : "Vis underpunkter"
                                        }
                                    />
                                    <span>Lovhjemler</span>
                                </button>
                                <div
                                    className={`${this.state.lawReferencesIsExpanded ? style.expanded : ""} ${
                                        style.expandableListContent
                                    }`}
                                >
                                    {this.renderLawReferencesList(
                                        this.props.activity.lawReferences,
                                        this.state.editable,
                                        theme
                                    )}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Resultat/Utfall ********** */}

                        {isLoggedIn || this.state.activity?.actions?.length ? (
                            <div className={`${style.expandableList} ${style.activityDetailsField}`}>
                                <button
                                    type="button"
                                    aria-expanded={this.state.actionsIsExpanded ? "true" : "false"}
                                    onClick={() => this.toggleExpandActionsClick()}
                                    className={`${style.expandableListHeader} ${style.unstyled}`}
                                >
                                    <FontAwesomeIcon
                                        className={style.expandButton}
                                        icon={this.state.actionsIsExpanded ? "chevron-down" : "chevron-right"}
                                        alt={this.state.actionsIsExpanded ? "Skjul underpunkter" : "Vis underpunkter"}
                                    />
                                    <span>Resultat/utfall</span>
                                </button>
                                <div
                                    className={`${this.state.actionsIsExpanded ? style.expanded : ""} ${
                                        style.expandableListContent
                                    }`}
                                >
                                    {this.renderActionsList(
                                        this.props.activity.actions,
                                        this.props.actions,
                                        this.state.editable,
                                        theme
                                    )}
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {/* ********* Tiltakstype ********** */}

                        {isLoggedIn ||
                        (this.state.activity?.enterpriseTerms?.length &&
                            this.showFilter(this.props.filters, "EnterpriseTerms")) ? (
                            <div className={`${style.expandableList} ${style.activityDetailsField}`}>
                                <button
                                    type="button"
                                    aria-expanded={this.state.enterPriseTermsIsExpanded ? "true" : "false"}
                                    onClick={() => this.toggleExpandEnterpriseTermsClick()}
                                    className={`${style.expandableListHeader} ${style.unstyled}`}
                                >
                                    <FontAwesomeIcon
                                        className={style.expandButton}
                                        icon={this.state.enterPriseTermsIsExpanded ? "chevron-down" : "chevron-right"}
                                        alt={
                                            this.state.enterPriseTermsIsExpanded
                                                ? "Skjul underpunkter"
                                                : "Vis underpunkter"
                                        }
                                    />
                                    <span>
                                        {this.props.filters.find((n) => n.modelName === "EnterpriseTerms")?.name}{" "}
                                    </span>

                                    {getEnvironmentVariable("enterpriseTermsApiUrl") !== null ? (
                                        <span>
                                            (Fra{" "}
                                            <a
                                                href={getEnvironmentVariable("enterpriseTermsApiUrl")}
                                                rel="noreferrer"
                                                target="_blank"
                                                aria-label="kodeliste (åpnes i nytt vindu)"
                                            >
                                                kodeliste
                                            </a>
                                            )
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </button>
                                <div
                                    className={`${this.state.enterPriseTermsIsExpanded ? style.expanded : ""} ${
                                        style.expandableListContent
                                    }`}
                                >
                                    <EnterpriseTerms
                                        editable={this.state.editable}
                                        enterpriseTerms={this.getOptionValuesForFilterType(
                                            this.props.filters,
                                            "EnterpriseTerms"
                                        )}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {userFeedbackHtml?.length ? (
                            <div className={style.infoBox} style={infoBoxStyle}>
                                <span className={style.icon}>
                                    <FontAwesomeIcon icon={"info-circle"} />
                                </span>
                                <span>{userFeedbackHtml}</span>
                            </div>
                        ) : null}
                    </div>
                    <div className={style.actionButtons}>
                        {this.renderActionButton(processCategoryKey, activityId, theme)}
                        <div className={style.navigateToTop}>
                            <Button type="button" color="primary" theme={theme}>
                                Hopp til toppen
                            </Button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

ActivityDetails.propTypes = {
    activityId: PropTypes.string.isRequired,
    processCategoryKey: PropTypes.string,
    sidebarView: PropTypes.bool
};

const mapStateToProps = (state) => ({
    oidc: state.oidc,
    activity: state.activity,
    processCategories: state.processCategories,
    filters: state.facets,
    actions: state.actions,
    location: state.router.location,
    selectedEnterpriseTerms: state.selectedEnterpriseTerms,
    selectedLanguage: state.selectedLanguage,
    metadataTypes: state.metadataTypes
});

const mapDispatchToProps = {
    fetchActivities,
    fetchActivity,
    createDraftActivity,
    updateDraftActivity,
    updatePublishedActivity,
    updateSelectedEnterpriseTerms,
    fetchFacets,
    fetchProcessCategories,
    fetchActions,
    fetchMetadataTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetails);
