// Dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { InputField } from "dibk-design";

// Actions
import { updateSearchFilteredActivities } from "actions/SearchFilteredActivitiesActions";

// Stylesheets
import style from "./ActivitySearch.module.scss";
import { filterActivitiesWithSearchStringWords } from "helpers/activityHelpers";
import { convertSearchStringToWords } from "helpers/facetFilterHelpers";

function ActivitySearch({ searchableProperties }) {
    const dispatch = useDispatch();

    // State
    const [searchString, setSearchString] = useState("");

    // Redux store
    const activities = useSelector((state) => state.activities);

    function handleSearchChange(searchString) {
        setSearchString(searchString);
    }

    useEffect(() => {
        const searchStringWords = convertSearchStringToWords(searchString);
        const searchResults = filterActivitiesWithSearchStringWords(
            activities,
            searchStringWords,
            searchableProperties
        );
        dispatch(updateSearchFilteredActivities(searchResults));
    }, [activities, dispatch, searchString, searchableProperties]);

    return (
        <div className={style.container}>
            <InputField
                elementKey={`activitySearch`}
                onChange={(event) => handleSearchChange(event?.target?.value)}
                id="activitySearch"
                label="Søk"
                type="search"
                role="search"
                defaultValue={searchString}
                placeholder="Søk etter sjekkpunkt"
            />
        </div>
    );
}

ActivitySearch.propTypes = {
    searchableProperties: PropTypes.arrayOf(PropTypes.string)
};

export default ActivitySearch;
