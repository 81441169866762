// Dependencies
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Helpers
import { containsLineBreakElements, getElementsFromStringWithLinks, removeLineBreakElements } from "./dataHelpers";
import { getNavigationBarTextColor } from "./environmentVariableHelpers";

export const renderUserFeedbackHtml = (userFeedbackHtml, isNavigationBar) => {
    const elements = getElementsFromStringWithLinks(userFeedbackHtml);
    return elements?.length
        ? elements.map((element, index) => {
              const key = `parsed-string-element-${index}`;
              let content = element?.children;
              const containsLineBreaks = containsLineBreakElements(content);
              if (containsLineBreaks) {
                  content = removeLineBreakElements(content);
              }
              const elementStyle = {
                  [containsLineBreaks ? "display" : null]: "block",
                  [isNavigationBar ? "color" : null]: getNavigationBarTextColor()
              };
              const coffeeCupElement = isNavigationBar && index === 0 && (
                  <FontAwesomeIcon icon={"coffee"} style={{ marginRight: "8px" }} />
              );
              if (element.type === "text") {
                  return (
                      <span key={key} style={elementStyle}>
                          {coffeeCupElement}
                          {content}
                      </span>
                  );
              } else if (element.type === "link") {
                  return (
                      <a key={key} href={element.href} style={elementStyle}>
                          {coffeeCupElement}
                          {content}
                      </a>
                  );
              } else {
                  return null;
              }
          })
        : null;
};
