// Dependencies
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";

// DIBK Design
import {InputField} from 'dibk-design';

// Helpers
import {getQueryStringFromFacets} from 'helpers/facetFilterHelpers';
import {convertInputDateToUrlString} from 'helpers/dateHelpers';

// Stylesheets
import style from "./ValidDate.module.scss";


class ValidDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.redirect) {
      this.setState({redirect: null});
    }
  }


  getUpdateFacetQueryString(value) {
    return getQueryStringFromFacets(this.props.selectedFacets, value, this.props.searchString, {
      facetToRemove: {
        facetField: 'validDate',
        facetKey: value,
        facet: null
      }
    });
  }


  handleDateChange = (value) => {
    const redirect = `${this.props.location.pathname}${this.getUpdateFacetQueryString(value, 'validDate')}`;
    this.setState({
      redirect
    });
  }

  render() {
    return this.state.redirect
      ? <Redirect to={this.state.redirect}/>
      : (
      <Fragment>
        <InputField onChange={event => this.handleDateChange(convertInputDateToUrlString(event.target.value))}
                    key={`activityDetails-validDate`}
                    id="activityDetails-validDate"
                    label="Gyldig dato"
                    type="date"
                    defaultValue={this.props.selectedValidDate || ''}
                    aria-describedby="activityDetails-validDate-description"
                    defaultContent="Dato er ikke angitt" /> 
                  <div id="activityDetails-validDate-description" className={style.hidden}>Datoformat: DD/MM/ÅÅÅÅ</div>
        </Fragment>
      )


  }
}

const mapStateToProps = state => (
  {
    location: state.router.location,
    selectedFacets: state.selectedFacets,
    selectedValidDate: state.selectedValidDate
  }
);


export default connect(mapStateToProps, null)(ValidDate);
