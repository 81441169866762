// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// DIBK Design
import { CheckBoxInput } from "dibk-design";

// Helpers
import { getQueryStringFromFacets, getParentFacets, getOrderedFacetsByNameArray } from "helpers/facetFilterHelpers";
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// Stylesheets
import style from "./Facet.module.scss";
class Facet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            link: "",
            redirect: null
        };
    }

    componentDidMount() {
        const selectedFacetsForField = this.props?.selectedFacets?.[this.props.facetField.modelName]?.facets;
        this.isChecked(selectedFacetsForField);
        if (this.state.redirect !== null) {
            this.setState({ redirect: null });
        }
    }

    componentDidUpdate(prevProps) {
        const oldUrlParameterString = prevProps.location?.search || "";
        const newUrlParameterString = this.props.location?.search || "";
        const urlParameterStringHasChanged = oldUrlParameterString !== newUrlParameterString;

        if (urlParameterStringHasChanged) {
            const selectedFacetsForField = this.props.selectedFacets?.[this.props.facetField.modelName]?.facets;
            this.isChecked(selectedFacetsForField);
        }
        if (this.state.redirect !== null) {
            this.setState({ redirect: null });
        }
    }

    renderSubFilterItems() {
        return getOrderedFacetsByNameArray(this.props.facet.filters).map((facet) => {
            facet.parent = this.props.facetKey;
            return (
                <Facet
                    facet={facet}
                    parentChecked={this.state.checked}
                    facetField={this.props.facetField}
                    facetKey={facet.facetKey}
                    key={facet.facetKey}
                    selectedFacets={this.props.selectedFacets}
                    selectedValidDate={this.props.selectedValidDate}
                    selectedProcessCategoryKey={this.props.selectedProcessCategoryKey}
                    location={this.props.location}
                />
            );
        });
    }

    getClosestParentSelectedFacetsArray(parents, facets, index = 0) {
        facets = facets ? facets : this.props.selectedFacets[this.props.facetField.modelName].facets;
        if (facets && parents && parents.length) {
            if (index < parents.length - 1) {
                this.getClosestParentSelectedFacetsArray(
                    parents,
                    facets[parents[index].facet.facetKey].facets,
                    index + 1
                );
            }
            const hasSelectedFacets =
                facets[parents[index].facet.facetKey] && facets[parents[index].facet.facetKey].facets;
            if (hasSelectedFacets) {
                return facets[parents[index].facet.facetKey].facets;
            } else {
                return null;
            }
        } else {
            return facets;
        }
    }

    getSelectedFacetByFacetKey(selectedFacets, facetKey) {
        Object.keys(selectedFacets).find((selectedFacetKey) => {
            const facet = selectedFacets[selectedFacetKey];
            return facetKey === selectedFacetKey || this.getSelectedFacetByFacetKey(facet.filters, facetKey);
        });
    }

    isChecked = (selectedFacetsForField) => {
        const parentFacets = getParentFacets(this.props.facet);
        if (selectedFacetsForField) {
            const isChecked = Object.keys(selectedFacetsForField).find((facetKey) => {
                const facetToFind = selectedFacetsForField[facetKey];
                if (parentFacets) {
                    const hasSelectedParentFacet = Object.keys(selectedFacetsForField).includes(parentFacets[0]); // TODO improve logic if number of hierarchy levels is more than one
                    const selectedParentFacet = selectedFacetsForField[facetKey];
                    if (hasSelectedParentFacet) {
                        const isSelected = selectedParentFacet.facets[this.props.facetKey];
                        if (isSelected) {
                            return true;
                        }
                    }
                    return false;
                } else {
                    return facetToFind.id === this.props.facet.id;
                }
            });
            if (isChecked) {
                this.setState({ checked: true, link: this.getRemoveFacetQueryString() });
            } else {
                this.setState({ checked: false, link: this.getAddFacetQueryString() });
            }
        } else {
            this.setState({ checked: false, link: this.getAddFacetQueryString() });
        }
    };

    getAddFacetQueryString() {
        const facetKey = this.props.facetKey;
        return getQueryStringFromFacets(
            this.props.selectedFacets,
            this.props.selectedValidDate,
            this.props.searchString,
            {
                facetToAdd: {
                    facetField: this.props.facetField.modelName,
                    facetKey,
                    facet: {
                        ...this.props.facet,
                        facets: this.props.facet.filters
                    }
                }
            }
        );
    }

    getRemoveFacetQueryString() {
        const facetKey = this.props.facetKey;
        return getQueryStringFromFacets(
            this.props.selectedFacets,
            this.props.selectedValidDate,
            this.props.searchString,
            {
                facetToRemove: {
                    facetField: this.props.facetField.modelName,
                    facetKey,
                    facet: {
                        ...this.props.facet,
                        facets: this.props.facet.filters
                    }
                }
            }
        );
    }

    handleFacetClick = () => {
        const selectedProcessCategoryKey = this.props.selectedProcessCategoryKey;
        const query = this.state.checked ? this.getRemoveFacetQueryString() : this.getAddFacetQueryString();
        const url = `/checklist/${selectedProcessCategoryKey}/${query}`;
        this.setState({ redirect: url });
    };

    render() {
        const theme = getEnvironmentVariable("theme");
        const expandableFacet = this.props.facet.filters && Object.keys(this.props.facet.filters).length > 0;
        const subItemsElementId = `${this.props.facetField.name}-${this.props.facet.name}-subItems`;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        let inputProps = {
            id: `${this.props.facetField.name}-${this.props.facet.name}`,
            name: this.props.facetField.name,
            checked: this.state.checked,
            theme,
            onChange: this.handleFacetClick
        };
        if (expandableFacet) {
            inputProps["aria-description"] = `${Object.keys(this.props.facet.filters).length} underelementer`;
            inputProps["aria-controls"] = subItemsElementId;
        }
        return (
            <div className={style.filterItemContainer} style={{ display: this.props.parentChecked ? "block" : "none" }}>
                <div className={style.filterItem}>
                    <CheckBoxInput {...inputProps}>{this.props.facet.name}</CheckBoxInput>
                </div>
                {expandableFacet ? (
                    <ul id={subItemsElementId} aria-live="polite">
                        {this.renderSubFilterItems()}
                    </ul>
                ) : null}
            </div>
        );
    }
}

Facet.propTypes = {
    facet: PropTypes.object.isRequired,
    facetKey: PropTypes.string.isRequired,
    facetField: PropTypes.object.isRequired,
    parentChecked: PropTypes.bool
};

Facet.defaultProps = {
    parentChecked: true
};

const mapStateToProps = (state) => ({
    location: state.router.location,
    selectedFacets: state.selectedFacets,
    selectedValidDate: state.selectedValidDate,
    selectedProcessCategoryKey: state.selectedProcessCategoryKey
});

export default connect(mapStateToProps, null)(Facet);
