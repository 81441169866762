import { diff } from "deep-object-diff";

export const convertObjectToFormdata = (object) => {
    let formData = new FormData();

    for (let key in object) {
        formData.append(key, object[key]);
    }

    return formData;
};

export const isEmpty = (object) => {
    return Object.keys(object).length === 0;
};

export const objectHasChanged = (origObject, object) => {
    const difference = diff(origObject, object);

    return !isEmpty(difference);
};

export const createRandomId = () => Math.random().toString(36).substring(2, 11);

export const getStringBetweenStrings = (startString, endString, string) => {
    const position = string.indexOf(startString) + startString.length;
    return string.substring(position, string.indexOf(endString, position));
};

export const getElementsFromStringWithLinks = (userFeedbackHtml) => {
    const regex = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g;
    let m;

    const elements = [];
    let stringStartPos = 0;

    while ((m = regex.exec(userFeedbackHtml)) !== null) {
        // Avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        // Add text element
        const stringEndPos = m.index - stringStartPos;
        if (stringEndPos > stringStartPos) {
            const textBeforeLink = userFeedbackHtml.substr(stringStartPos, m.index - stringStartPos);
            elements.push({ type: "text", children: textBeforeLink });
        }

        stringStartPos = m.index + m[0].length;

        // Add link element
        const linkHref = getStringBetweenStrings("href='", "'", m[1]);
        const linkText = m[2];
        elements.push({ type: "link", href: linkHref, children: linkText });
    }

    // Add text after last link
    if (userFeedbackHtml?.length > stringStartPos) {
        const textAfterLastLink = userFeedbackHtml.substr(stringStartPos, userFeedbackHtml.length);
        elements.push({ type: "text", children: textAfterLastLink });
    }

    if (elements.length) {
        return elements;
    } else if (userFeedbackHtml?.length) {
        return [{ type: "text", children: userFeedbackHtml }];
    } else return null;
};

const lineBreakRegex = /(<|&lt;)br\s*\/*(>|&gt;)/g;

export const containsLineBreakElements = (content) => {
    return content?.match(lineBreakRegex);
};

export const removeLineBreakElements = (content) => {
    return content.replace(lineBreakRegex, "");
};
