export const setFocusToElement = (element) => {
    const autoFocusElement = document.createElement("button");
    element.prepend(autoFocusElement);
    autoFocusElement.focus();
    autoFocusElement.remove();
};

export const setFocusBeforeElement = (element) => {
    const autoFocusElement = document.createElement("button");
    element.before(autoFocusElement);
    autoFocusElement.focus();
    autoFocusElement.remove();
};

export const getFocusableElementsInsideElement = (element) => {
    return element.querySelectorAll('button, [href], input, [tabindex="0"]');
};
