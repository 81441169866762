// Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// DIBK Design
import { Header, List, ListItem } from 'dibk-design';

// components
import EnterpriseTerm from 'components/partials/EnterpriseTerms/EnterpriseTerm'


class EnterpriseTerms extends Component {
  renderEnterpriseTerms(enterpriseTerms) {
    const enterpriseTermsElements = enterpriseTerms.length
      ? enterpriseTerms.map((enterpriseTerm, enterpriseTermIndex) => {
        return enterpriseTerm.isGroup
          ? (
            <div key={enterpriseTerm.value}>
              <Header content={enterpriseTerm.key} size={5} htmlTag="h4" />
              <EnterpriseTerms editable={this.props.editable} enterpriseTerms={enterpriseTerm.filters} />
            </div>
          )
          : (
            <ListItem key={enterpriseTerm.value}>
              <EnterpriseTerm enterpriseTermName={enterpriseTerm.key} enterpriseTermCode={enterpriseTerm.value} editable={this.props.editable} />
            </ListItem>
            );
      })
      : '';
      return !!enterpriseTermsElements.length ? (
        <List listStyle="none" compact>
          {enterpriseTermsElements}
        </List>
      ) : null
  }
  render() {
    return this.props.enterpriseTerms
      ? this.renderEnterpriseTerms(this.props.enterpriseTerms)
      : '';
  }
}

EnterpriseTerms.propTypes = {
  editable: PropTypes.bool,
  enterpriseTerms: PropTypes.arrayOf(PropTypes.object)
};

EnterpriseTerms.defaultProps = {
  editable: false
}

export default connect(null, null)(EnterpriseTerms);
