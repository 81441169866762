// Dependencies
import React, {Component} from 'react';
import {connect} from 'react-redux';

// Components
import Facets from '../partials/Facets';
import Activities from '../partials/Activities';
import ActivityDetails from '../partials/ActivityDetails';

// DIBK Design
import {Header} from 'dibk-design';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// Actions
import {updateSelectedProcessCategoryKey} from '../../actions/SelectedProcessCategoryKeyActions';
import { updateSelectedFacetsFromUrl } from 'actions/FacetsActions';
import { updateSelectedValidDateFromUrl } from 'actions/SelectedValidDateActions';

import {getProcessCategoryByKey} from 'actions/ProcessCategoriesActions';

// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';
import { setFocusBeforeElement } from 'helpers/guiHelpers';

// Stylesheets
import style from './ProcessCategory.module.scss';


class ProcessCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      facetExpanded: false
    }
  }

  componentDidMount() {
    this.props.updateSelectedProcessCategoryKey(this.props.match.params.processCategoryKey);
  }

  componentDidUpdate(prevProps) {
    setFocusBeforeElement(document.getElementById("root"));
    const processCategoryKeyHasChanged = prevProps.match.params.processCategoryKey !== this.props.match.params.processCategoryKey;
    if (processCategoryKeyHasChanged) {
      this.props.updateSelectedProcessCategoryKey(this.props.match.params.processCategoryKey);
    }
    const hadFacets = prevProps.length && prevProps.facets;
    const hasFacets = this.props.facets && this.props.facets.length;
    if (!hadFacets && hasFacets){
      const availableFacets = {};
      
      this.props.facets.forEach(facet => {
        if(availableFacets[facet.modelName]){
          availableFacets[facet.modelName].filters = {...availableFacets[facet.modelName].filters, ...facet.filters};
        }
        else{
          availableFacets[facet.modelName] = {filters: facet.filters};
        }
      });

      this.props.updateSelectedFacetsFromUrl(availableFacets);
    }
    this.props.updateSelectedValidDateFromUrl();
  }

  renderActivityDetails(processCategoryKey, activityId) {
    return activityId
      ? (<ActivityDetails processCategoryKey={processCategoryKey} activityId={activityId} sidebarView={true} />)
      : '';
  }
  toogleFacetsClick() {
    this.setState({
      facetExpanded: !this.state.facetExpanded
    });
  }

  render() {
    const processCategoryKey = this.props.match.params.processCategoryKey;
    const activityId = this.props.match.params.activityId;
    const selectedProcessCategory = this.props.getProcessCategoryByKey(processCategoryKey);
    const expandButton =
    <FontAwesomeIcon onClick={() => this.toogleFacetsClick()} className={style.expandBtn} icon={this.state.facetExpanded
        ? 'times'
        : 'filter'} />
    const theme = getEnvironmentVariable('theme');
    const filterContainerStyle = {
      background: theme && theme.colors && theme.colors.sidebarBackground ? theme.colors.sidebarBackground : null
    };
    return (<div>
      <div className={style.hidden}>
        <Header content={selectedProcessCategory && selectedProcessCategory.name ? selectedProcessCategory.name : processCategoryKey}/>
      </div>

      <div className={style.mainContainer}>
      <div className={`${this.state.facetExpanded ? style.close : style.open} ${style.expButton}`}>
       {expandButton}
       </div>
        <div className={`${this.state.facetExpanded
                ? style.expanded
                : ''} ${style.filterContainer}`} style={filterContainerStyle}>
          <Facets/>
        </div>
        <div id="main-content" className={`${this.state.facetExpanded
                ? style.expanded
                : ''} ${style.checklistContainer}`}>
          <div className={style.checklistContainerHeader}>
            <Header content={selectedProcessCategory && selectedProcessCategory.name ? selectedProcessCategory.name : processCategoryKey} htmlTag="p"/>
          </div>
          <div>
            <div className={style.description}>
               {selectedProcessCategory && selectedProcessCategory.description ? selectedProcessCategory.description : ''} </div>
          </div>
          <Activities processCategoryKey={processCategoryKey}/>
        </div>
        <div className={`${style.activityDetailsOverlay} ${processCategoryKey && activityId ? style.open : ''}`}>
          <div className={`${processCategoryKey && activityId
              ? style.openActivityDetailsContainer
              : style.hiddenActivityDetailsContainer}`}>
            {this.renderActivityDetails(processCategoryKey, activityId)}
          </div>
        </div>
      </div>
    </div>)
  }
}

const mapStateToProps = state => ({location: state.router.location, processCategories: state.processCategories, facets: state.facets});

const mapDispatchToProps = {
  updateSelectedProcessCategoryKey,
  getProcessCategoryByKey,
  updateSelectedFacetsFromUrl,
  updateSelectedValidDateFromUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessCategory);
