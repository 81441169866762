// Dependencies
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// DIBK Design
import {CheckBoxInput} from 'dibk-design';

// Actions
import {addSelectedEnterpriseTerm, removeSelectedEnterpriseTerm} from '../../../actions/SelectedEnterpriseTermsActions';

// Helpers
import {getEnvironmentVariable} from 'helpers/environmentVariableHelpers.js';


// Stylesheets
class EnterpriseTerm extends Component {

  enterpriseTermCheckboxOnChange(enterpriseTermCode, isChecked) {
    isChecked
      ? this.props.removeSelectedEnterpriseTerm(enterpriseTermCode)
      : this.props.addSelectedEnterpriseTerm(enterpriseTermCode);
    this.setState({})
  }

  isEnterpriseTermChecked(enterpriseTermCode, selectedEnterpriseTerms) {
    if (selectedEnterpriseTerms && selectedEnterpriseTerms.length) {
      return selectedEnterpriseTerms.find(selectedEnterpriseTerm => {
        return selectedEnterpriseTerm.code === enterpriseTermCode
      })
        ? true
        : false;
    } else {
      return false;
    }
  }

  render() {
    const theme = getEnvironmentVariable('theme');
    if (this.props.enterpriseTermName && this.props.enterpriseTermCode && this.props.selectedEnterpriseTerms) {
      const isChecked = this.isEnterpriseTermChecked(this.props.enterpriseTermCode, this.props.selectedEnterpriseTerms);
      if (this.props.editable) {
        return (<CheckBoxInput id={this.props.enterpriseTermCode} checked={isChecked} onChange={() => this.enterpriseTermCheckboxOnChange(this.props.enterpriseTermCode, isChecked)} theme={theme}>
          {this.props.enterpriseTermName}
        </CheckBoxInput>)
      } else if (isChecked) {
        return this.props.enterpriseTermName
      } else {
        return ''
      }
    }
  }
}

EnterpriseTerm.propTypes = {
  enterpriseTermName: PropTypes.string,
  enterpriseTermCode: PropTypes.string.isRequired,
  editable: PropTypes.bool
};

EnterpriseTerm.defaultProps = {
  editable: false
}

const mapStateToProps = state => ({enterpriseTerms: state.enterpriseTerms, selectedEnterpriseTerms: state.selectedEnterpriseTerms});

const mapDispatchToProps = {
  addSelectedEnterpriseTerm,
  removeSelectedEnterpriseTerm
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseTerm);
