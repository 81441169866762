// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// DIBK Design
import { RadioButtonList, RadioButtonListItem, Header } from "dibk-design";

// Actions
import { fetchProcessCategories } from "actions/ProcessCategoriesActions";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// Stylesheets
import style from "./ProcessCategorySection.module.scss";

class ProcessCategorySection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            autoFocusOnElement: null
        };
    }

    componentDidMount() {
        this.props.fetchProcessCategories();
        if (this.state.redirect !== null) {
            this.setState({ redirect: null });
        }
    }

    componentDidUpdate() {
        if (this.state.redirect !== null) {
            this.setState({ redirect: null });
        }
        if (!!this.props?.location?.state?.autoFocusOnSelectedRadioButton){
            const selectedRadioButtonElement = this.getSelectedRadioButtonElement();
            if (selectedRadioButtonElement){
                selectedRadioButtonElement.focus();
            }
        }
    }

    getSelectedRadioButtonElement() {
        const radioButtonElements = document.getElementsByName('processCategory');
        return Array.from(radioButtonElements).find(radioButtonElement => {
            return radioButtonElement.checked;
        });
    }

    renderUserFeedbackEmailAddress() {
        return getEnvironmentVariable("userFeedbackEmail");
    }

    handleOnSelectedProcessCategoryKeyChange(selectedProcessCategoryKey) {
        const url = `/checklist/${selectedProcessCategoryKey}`;
        this.setState(
            {
                redirect: url
            }
        );
    }

    renderProcessCategoryLinks(processCategories) {
        const theme = getEnvironmentVariable("theme");
        const hasProcessCategories =
            processCategories && processCategories.filters && Object.keys(processCategories.filters).length;
        const processCategoriesElement = hasProcessCategories
            ? Object.keys(processCategories.filters).map((processCategoryKey) => {
                  const processCategory = processCategories.filters[processCategoryKey];
                  return (
                      <RadioButtonListItem
                          id={`processCategory-${processCategoryKey}`}
                          name={`processCategory`}
                          onChange={() => this.handleOnSelectedProcessCategoryKeyChange(processCategoryKey)}
                          inputValue={processCategoryKey}
                          key={processCategoryKey}
                          checked={this.props.selectedProcessCategoryKey === processCategoryKey}
                          theme={theme}
                          compact
                      >
                          {processCategory.name}
                      </RadioButtonListItem>
                  );
              })
            : [];
        return (
            <fieldset className={style.processCategorySection}>
                <Header content={this.props.processCategories.name} size={3} />
                <RadioButtonList compact>{processCategoriesElement}</RadioButtonList>
            </fieldset>
        );
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={
                {
                    pathname: this.state.redirect,
                    state: {
                        autoFocusOnSelectedRadioButton: true
                    }
                }
            } />;
        }
        return this.props.processCategories ? this.renderProcessCategoryLinks(this.props.processCategories) : "";
    }
}

const mapStateToProps = (state) => ({
    processCategories: state.processCategories,
    selectedProcessCategoryKey: state.selectedProcessCategoryKey,
    location: state.router.location
});

const mapDispatchToProps = {
    fetchProcessCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessCategorySection);
