// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Components
import Facet from "./FacetField/Facet";

// Helpers
import { getOrderedFacetsByNameArray } from "helpers/facetFilterHelpers";

// DIBK Design
import { Header } from "dibk-design";

// Stylesheets
import style from "./FacetField.module.scss";

class FacetField extends Component {
    renderFilterItems(facetField) {
        const hasFilters = facetField?.filters && Object.keys(facetField.filters).length;
        return hasFilters
            ? getOrderedFacetsByNameArray(facetField.filters).map((facet) => {
                  return <Facet facet={facet} facetKey={facet.facetKey} facetField={facetField} key={facet.facetKey} />;
              })
            : null;
    }
    render() {
        const shouldRenderFacetField =
            this.renderFilterItems(this.props.facetField)?.length > 0 &&
            this.props.facetField.showAsFacetFilter !== false;
        return shouldRenderFacetField ? (
            <fieldset className={style.filterSection}>
                <legend>
                    <Header content={this.props.facetField.name} size={3} />
                </legend>
                <div className={style.facetFilterItemsContainer}>{this.renderFilterItems(this.props.facetField)}</div>
            </fieldset>
        ) : null;
    }
}

FacetField.propTypes = {
    facetField: PropTypes.object.isRequired
};

export default connect(null, null)(FacetField);
