// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";

// Components
import HomeContent from "components/partials/HomeContent";
import Footer from "components/partials/Footer";

// Actions
import { fetchProcessCategories } from "../../actions/ProcessCategoriesActions";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// DIBK Design
import { Button } from "dibk-design";

// Stylesheets
import style from "./Wizard.module.scss";

class Home extends Component {
    constructor(props) {
        super(props);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    componentDidMount() {
        this.props.fetchProcessCategories();
    }

    handleLoginClick(event) {
        event.preventDefault();
        this.props.userManager.signinRedirect({ state: { pathname: window.location.pathname } });
    }

    handleLogoutClick(event) {
        event.preventDefault();
        this.props.userManager.signoutRedirect({ id_token_hint: this.props.user.id_token });
        this.props.userManager.removeUser();
    }

    renderLoginLink(theme) {
        return (
            <div className={style.Link}>
                <Button type="button" size="small" content="Logg inn" onClick={this.handleLoginClick} theme={theme} />
            </div>
        );
    }

    renderLogoutLink(theme) {
        return (
            <div className={style.navigationBarButton}>
                <Button type="button" size="small" content="Logg ut" onClick={this.handleLogoutClick} theme={theme} />
            </div>
        );
    }

    render() {
        const theme = getEnvironmentVariable("theme");
        return (
            <div id="main-content" className={style.container}>
                <div className={style.contentContainer}>
                    <HomeContent />
                    <div>
                        <div>
                            <h3>For administrator av sjekklisten</h3>
                        </div>
                        {this.props.user ? this.renderLogoutLink(theme) : this.renderLoginLink(theme)}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    processCategories: state.processCategories,
    user: state.oidc.user
});

const mapDispatchToProps = {
    fetchProcessCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
