import React from "react";
import { createRoot } from 'react-dom/client';
import "index.scss";
import App from "App";
import * as serviceWorker from "serviceWorker";
import ConfigLoader from "components/ConfigLoader";
import "react-md-editor-override.css";

const Main = () => {
    return <ConfigLoader ready={() => <App />} />;
};


const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Main />);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
