const initTheme = (theme) => {
    if (theme) {
        let style = document.createElement("style");
        style.innerHTML = `
            body {
                background: ${theme.colors.bodyBackground};
                color: ${theme.colors.text};
            }
            a {
                color: ${theme.colors.link};
            }
        `;
        document.head.appendChild(style);
    }
}

export { initTheme };
