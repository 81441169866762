import React from 'react';
import { Paper } from 'dibk-design';
import { useGetStaticMetadataQuery } from 'store/slices/checklistApiSlice';
import { 
   useAddProcessCategoriesMutation, 
   useUpdateProcessCategoriesMutation,
   useAddMilestonesMutation, 
   useUpdateMilestonesMutation, 
   useAddActivityTypesMutation, 
   useUpdateActivityTypesMutation, 
   useAddCategoriesMutation, 
   useUpdateCategoriesMutation, 
   useAddEnterpriseTermsMutation, 
   useUpdateEnterpriseTermsMutation, 
   useAddOwnersMutation, 
   useUpdateOwnersMutation 
} from 'store/slices/adminApiSlice';
import StaticMetadataValues from '../StaticMetadataValues/StaticMetadataValues';
import './StaticMetadataValuesList.scss';

const MUTATION_HOOKS = [
   { id: 1, add: useAddProcessCategoriesMutation, update: useUpdateProcessCategoriesMutation  },
   { id: 2, add: useAddMilestonesMutation, update: useUpdateMilestonesMutation  },
   { id: 3, add: useAddActivityTypesMutation, update: useUpdateActivityTypesMutation  },
   { id: 4, add: useAddCategoriesMutation, update: useUpdateCategoriesMutation  },
   { id: 5, add: useAddEnterpriseTermsMutation, update: useUpdateEnterpriseTermsMutation  },
   { id: 6, add: useAddOwnersMutation, update: useUpdateOwnersMutation  },
];

function StaticMetadataValuesList() {
   const { data: staticMetadataValues = [] } = useGetStaticMetadataQuery();

   function getMutationHooks(filterId) {
      return MUTATION_HOOKS.find(hook => hook.id === filterId);
   }

   return (
      <div className="admin-right-content-container">
         <h1>Faste metadataverdier</h1>
         <Paper>
         {
            staticMetadataValues.length ?
               staticMetadataValues.map((filter, index) => {
                  return (
                     <StaticMetadataValues 
                        key={'filter-facet-' + index} 
                        filter={filter} 
                        mutationHooks={getMutationHooks(filter.id)} 
                     />
                  )
               }) :
               null
         }
         </Paper>
      </div>
   )
}

export default StaticMetadataValuesList;