// Dependencies
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

// DIBK Design
import {Header, Paper} from 'dibk-design';

// Components
import ActivityDetails from 'components/partials/ActivityDetails';

// Stylesheets
import style from 'components/routes/Activity.module.scss';

// Actions
import {updateDraftActivity} from 'actions/ActivityActions';
import {fetchActivity} from 'actions/ActivityActions';
import {updateSelectedEnterpriseTerms} from 'actions/SelectedEnterpriseTermsActions';
import {getProcessCategoryByKey} from 'actions/ProcessCategoriesActions';
import {fetchProcessCategories} from 'actions/ProcessCategoriesActions'

import Footer from 'components/partials/Footer';

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: null
    }
  }

  componentDidMount() {
    if(!this.props.processCategories) {
      this.props.fetchProcessCategories()
    }
    const activityId = this.props.match.params.activityId;
    this.props.fetchActivity(activityId).then((action) => {
      const activity = action && action.payload
        ? action.payload
        : null;
      const selectedEnterpriseTerms = activity.enterpriseTerms
        ? activity.enterpriseTerms
        : [];
      this.props.updateSelectedEnterpriseTerms(selectedEnterpriseTerms);
      this.setState({activity: activity});
    });
  }



  render() {
    const processCategoryKey = this.props.match.params.processCategoryKey;
    const selectedProcessCategory = this.props.getProcessCategoryByKey(processCategoryKey);
    const activityId = this.props.match.params.activityId;

    return this.state.activity
      ? (<div id="main-content" className={style.container}>
        <Header content={this.props.activity.name}/>

      <div aria-hidden="true"> <Link to={`/checklist/${processCategoryKey}${this.props.location.search}`} className={style.activityDetailsHeaderClose}>
     {selectedProcessCategory && selectedProcessCategory.name ? selectedProcessCategory.name : ''}
  </Link> / {this.props.activity.name}
  </div>

        <Paper>
          <ActivityDetails processCategoryKey={processCategoryKey} activityId={activityId}/>          
        </Paper>
        <Footer />
      </div>)
      : '';
  }
}

const mapStateToProps = state => ({activity: state.activity, oidc: state.oidc, processCategories: state.processCategories});

const mapDispatchToProps = {
  fetchActivity,
  updateDraftActivity,
  updateSelectedEnterpriseTerms,
  getProcessCategoryByKey,
  fetchProcessCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
