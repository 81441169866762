// Dependencies
import React from "react";
import { Link } from "react-router-dom";

// DIBK Design
import { Header, Button } from "dibk-design";

// Stylesheets
import wizardStyle from "components/routes/Wizard.module.scss";

const PlanHomeContent = ({ theme, headerContent, checklistApiUrl }) => {
    return (
        <div className={wizardStyle.introText}>
            <Header content={headerContent} />
            <Header content="Slik bruker du sjekklistene" size={2} />
            <p>
                Sjekklistene gir deg som plansaksbehandler en komplett liste over hvilke punkter kommunen skal vurdere i
                plansaker.
            </p>
            <p>Du får også en forklaring på hvordan vurderingen skal gjøres.</p>
            <p>
                Underlaget for dette arbeidet har blant annet vært KS sin kravspesifikasjon for ePlansak, KDD sine
                veiledere og sjekklister fra en rekke kommuner.
            </p>
            <div className={wizardStyle.buttonRow}>
                <Button type="button" color="primary" theme={theme}>
                    <Link to="/checklist">Velg sjekkliste</Link>
                </Button>
            </div>
            <div className={wizardStyle.ingress}>
                <p>Sjekklistene kan også brukes via vårt API.</p>
                <a href={checklistApiUrl}>Api for sjekklistene</a>
            </div>
        </div>
    );
};

export default PlanHomeContent;
