// Dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import { fetchProcessCategories } from "actions/ProcessCategoriesActions";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

// DIBK Design
import { Button, Header, Paper, RadioButtonList, RadioButtonListItem } from "dibk-design";

// Stylesheets
import style from "./Wizard.module.scss";

import Footer from "components/partials/Footer";

class SelectProcessCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProcessCategoryKey: null
        };
    }

    componentDidMount() {
        this.props.fetchProcessCategories();
    }

    handleOnSelectedProcessCategoryKeyChange(selectedProcessCategoryKey) {
        this.setState({
            selectedProcessCategoryKey
        });
    }

    renderProcessCategoryLinks(processCategories) {
        const hasProcessCategories =
            processCategories && processCategories.filters && Object.keys(processCategories.filters).length;
        return hasProcessCategories
            ? Object.keys(processCategories.filters).map((processCategoryKey) => {
                  const processCategory = processCategories.filters[processCategoryKey];
                  return (
                      <Link to={`/checklist/${processCategoryKey}`} key={processCategoryKey} className={style.listItem}>
                          {processCategory.name}
                      </Link>
                  );
              })
            : "";
    }
    renderProcessCategoryRadioButtonList(processCategories, theme) {
        const hasProcessCategories = processCategories?.filters && Object.keys(processCategories.filters)?.length;
        if (hasProcessCategories) {
            const listElements = Object.keys(processCategories.filters).map((processCategoryKey) => {
                const processCategory = processCategories.filters[processCategoryKey];
                return (
                    <RadioButtonListItem
                        id={`processCategory-${processCategoryKey}`}
                        name="processCategory"
                        onChange={() => this.handleOnSelectedProcessCategoryKeyChange(processCategoryKey)}
                        inputValue={processCategoryKey}
                        key={processCategoryKey}
                        checked={this.state.selectedProcessCategoryKey === processCategoryKey}
                        theme={theme}
                    >
                        {processCategory.name}
                    </RadioButtonListItem>
                );
            });
            return <RadioButtonList>{listElements}</RadioButtonList>;
        }
    }
    render() {
        const theme = getEnvironmentVariable("theme");
        return (
            <div id="main-content" className={style.container}>
                <div className={style.contentContainer}>
                    <div className={style.introText}>
                        <Header content={getEnvironmentVariable("Header")} />
                        <div className={style.ingress}></div>
                    </div>
                    <div className={style.btnsrow}>
                        <Paper>
                            <fieldset>
                                <legend>
                                    <Header
                                        content={`Velg ${
                                            this.props?.processCategories?.name?.length
                                                ? this.props.processCategories.name.toLowerCase()
                                                : "søknadstype"
                                        } for å se aktuelle sjekkpunkter`}
                                        size={2}
                                    />
                                </legend>
                                {this.props.processCategories
                                    ? this.renderProcessCategoryRadioButtonList(this.props.processCategories, theme)
                                    : ""}
                            </fieldset>
                        </Paper>
                    </div>
                    <div className={style.buttonRow}>
                        <Button type="button" color="primary" arrow="left" theme={theme}>
                            <Link to="/">Forrige</Link>
                        </Button>
                        <Button
                            color="primary"
                            type="button"
                            arrow="right"
                            disabled={!this.state.selectedProcessCategoryKey}
                            theme={theme}
                        >
                            <Link
                                to={
                                    this.state.selectedProcessCategoryKey
                                        ? `/checklist/${this.state.selectedProcessCategoryKey}`
                                        : ""
                                }
                            >
                                Neste
                            </Link>
                        </Button>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    processCategories: state.processCategories
});

const mapDispatchToProps = {
    fetchProcessCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectProcessCategory);
